/* eslint-disable no-mixed-operators */
import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import CheckmarkIcon from '../img/Checkmark.png';
const { getReport } = require('../Api');

class reportSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      UserModalVisible: false,
      date: new Date(),
      isDatePickerFromVisible: false,
      isDatePickerToVisible: false,
      SelectedDateFrom: moment().format('YYYY-MM-DD'),
      SelectedDateTo: moment().format('YYYY-MM-DD'),
      UserID: '',
      Username: '',
      PlatFormList: [
        {
          PlatForm: 'M',
          CheckMark: true,
          ImageUri: require('../img/PlatFormIcon/MLogo.png'),
          Num: '1',
        },
        {
          PlatForm: 'K',
          CheckMark: true,
          ImageUri: require('../img/PlatFormIcon/KLogo.png'),
          Num: '2',
        },
        {
          PlatForm: 'T',
          CheckMark: true,
          ImageUri: require('../img/PlatFormIcon/TLogo.png'),
          Num: '3',
        },
        {
          PlatForm: 'S',
          CheckMark: true,
          ImageUri: require('../img/PlatFormIcon/SLogo.png'),
          Num: '4',
        },
        {
          PlatForm: 'W',
          CheckMark: true,
          ImageUri: require('../img/PlatFormIcon/WLogo.png'),
          Num: '5',
        },
        {
          PlatForm: 'B',
          CheckMark: true,
          ImageUri: require('../img/PlatFormIcon/BLogo.png'),
          Num: '6',
        },
        {
          PlatForm: 'D',
          CheckMark: true,
          ImageUri: require('../img/PlatFormIcon/DLogo.png'),
          Num: '7',
        },
        {
          PlatForm: '8',
          CheckMark: true,
          ImageUri: require('../img/PlatFormIcon/GrandDragonLotto.png'),
          Num: '8',
        },
        {
          PlatForm: '9',
          CheckMark: true,
          ImageUri: require('../img/PlatFormIcon/NineLogo.png'),
          Num: '9',
        },
      ],
      PatternList: [
        {
          Pattern: language[this.props.currentLanguage].All,
          CheckMark: true,
          Name: 2,
        },
        {
          Pattern: language[this.props.currentLanguage].Normal,
          CheckMark: false,
          Name: 0,
        },
        {
          Pattern: 'SPR',
          CheckMark: false,
          Name: 1,
        },
        {
          Pattern: 'SGD',
          CheckMark: false,
          Name: 3,
        },
        {
          Pattern: 'THB',
          CheckMark: false,
          Name: 4,
        },
      ],  
    }
    this.SelectUserModal = this.SelectUserModal.bind(this);
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async setUserModalVisible() {
    const CheckVisible = this.state.UserModalVisible;
    if (CheckVisible === true) {
      this.setState({UserModalVisible: false});
    } else {
      this.setState({UserModalVisible: true});
    }
  }
  async SelectUserModal(SelectedID, SelectedUsername) {
    this.setState({
      UserID: SelectedID,
      Username: SelectedUsername,
      UserModalVisible: false,
    });
    this.props.setCheckReportInfo(
      SelectedID,
      SelectedUsername,
      this.state.SelectedDateFrom,
      this.state.SelectedDateTo,
      '',
    );
  }
  onPressShowDateFrom() {
    this.setState({isDatePickerFromVisible: true});
  }
  onPressShowDateTo() {
    this.setState({isDatePickerToVisible: true});
  }

  async setSelectedDate(date, mode) {
    this.setState({
      [mode]: moment(date).format('YYYY-MM-DD'),
    });
  }

  async componentDidMount() {
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    const PlatFormListData = this.state.PlatFormList;
    if (this.props.UserAndDownlineData[0].GameDisplay === 'MPTSWBK') {
      PlatFormListData[1].PlatForm = 'P';
      PlatFormListData[6].PlatForm = 'K';
    } else {
      PlatFormListData[1].PlatForm = 'K';
      PlatFormListData[6].PlatForm = 'D';
    }
    if (!this.props.UserAndDownlineData[0].GameBetting.includes('9')) {
      PlatFormListData.pop();
    }
    this.props.setCheckReportInfo(
      this.props.id,
      this.props.username,
      this.state.SelectedDateFrom,
      this.state.SelectedDateTo,
      '',
    );
    this.setState({PlatFormList: PlatFormListData, loading: false});
  }

  async onPressCheckReport() {
    this.setState({loading: true});
    let PlatformArray = [];
    let Pattern = [];
    for (var a = 0; a < this.state.PlatFormList.length; a++) {
      if (this.state.PlatFormList[a].CheckMark === true) {
        PlatformArray.push(this.state.PlatFormList[a].Num);
      }
    }
    for (var b = 0; b < this.state.PatternList.length; b++) {
      if (this.state.PatternList[b].CheckMark === true) {
        Pattern.push(this.state.PatternList[b].Name);
      }
    }
    if (PlatformArray.length < 1){
      this.setState({loading: false});
      alert(language[this.props.currentLanguage].NoSelectPlatformAlert);
    } else {
      sessionStorage.setItem('UserIDForSearch', this.props.UserIDForSearch);
      sessionStorage.setItem('UserForSearch', this.props.UserForSearch);
      sessionStorage.setItem('SearchDateFrom', this.state.SelectedDateFrom);
      sessionStorage.setItem('SearchDateTo', this.state.SelectedDateTo);
      sessionStorage.setItem('PatternForSearch', Pattern.toString()
        .replace('0', 'white')
        .replace('1', 'red')
        .replace('2', 'all')
        .replace('3','sgd')
        .replace('4', 'thai'),
      );
      sessionStorage.setItem('PlatformForSearch', JSON.stringify(PlatformArray));
      this.props.setCheckReportInfo(
        this.props.UserIDForSearch,
        this.props.UserForSearch,
        this.state.SelectedDateFrom,
        this.state.SelectedDateTo,
        '',
        Pattern.toString()
          .replace('0', 'white')
          .replace('1', 'red')
          .replace('2', 'all')
          .replace('3','sgd')
          .replace('4', 'thai'),
        PlatformArray,
      );
      const GetReportData = await getReport(
        this.props.username,
        moment(this.state.SelectedDateFrom).format('YYYY-MM-DD'),
        moment(this.state.SelectedDateTo).format('YYYY-MM-DD'),
        this.props.UserIDForSearch,
        this.props.token,
        Pattern.toString()
          .replace('0', 'white')
          .replace('1', 'red')
          .replace('2', 'all')
          .replace('3','sgd')
          .replace('4', 'thai'),
        PlatformArray,
        true,
      );
      if (
        GetReportData.error === 'Invalid fromDate' ||
      GetReportData.error === 'Invalid toDate'
      ) {
        this.setState({loading: false});
        alert(
          language[this.props.currentLanguage].DateInputError,
        );
      } else if (GetReportData.error === 'Invalid referenceUser') {
        this.setState({loading: false});
        alert(
          language[this.props.currentLanguage].InvalidreferenceUser,
        );
      } else if (GetReportData.error === 'fromDate bigger than toDate') {
        this.setState({loading: false});
        alert(
          language[this.props.currentLanguage].FromDateBiggerThan,
        );
      } else if (GetReportData.error) {
        this.setState({loading: false});
        alert(
          GetReportData.error,
        );
      } else if (GetReportData === 'TimeOut') {
        this.LogoutFunction();
      } else {
        this.setState({loading: false});
        sessionStorage.setItem('ReportData', JSON.stringify(GetReportData));
        this.props.setReportData(GetReportData);
        this.props.history.push('/displayReport');
      }
    }
  }

  async onPressTotalBTChecking() {
    this.setState({loading: true});
    let PlatformArray = [];
    let Pattern = [];
    for (var a = 0; a < this.state.PlatFormList.length; a++) {
      if (this.state.PlatFormList[a].CheckMark === true) {
        PlatformArray.push(this.state.PlatFormList[a].Num);
      }
    }
    for (var b = 0; b < this.state.PatternList.length; b++) {
      if (this.state.PatternList[b].CheckMark === true) {
        Pattern.push(this.state.PatternList[b].Name);
      }
    }
    if (PlatformArray.length < 1) {
      alert(
        language[this.props.currentLanguage].NoSelectPlatformAlert,
      );
    } else {
      this.props.setCheckReportInfo(
        this.props.UserIDForSearch,
        this.props.UserForSearch,
        this.state.SelectedDateFrom,
        this.state.SelectedDateTo,
        '',
        Pattern.toString()
          .replace('0', 'white')
          .replace('1', 'red')
          .replace('2', 'all')
          .replace('3', 'sgd')
          .replace('4', 'thai'),
        PlatformArray,
      );
      this.props.history.push('/reportBetChecking');
    }
  }

  async PlatformCheckboxSelect(idx) {
    const toProcessDateData = this.state.PlatFormList;
    if (toProcessDateData[idx].CheckMark === false) {
      toProcessDateData[idx].CheckMark = true;
    } else {
      toProcessDateData[idx].CheckMark = false;
    }
    this.setState({PlatFormList: toProcessDateData});
  }
  async PatternCheckboxSelect(idx) {
    const toProcessDateData = this.state.PatternList;
    if (idx === 0 && toProcessDateData[0].CheckMark === false) {
      toProcessDateData[0].CheckMark = true;
      toProcessDateData[1].CheckMark = false;
      toProcessDateData[2].CheckMark = false;
      toProcessDateData[3].CheckMark = false;
      toProcessDateData[4].CheckMark = false;
    }
    if (idx === 1 && toProcessDateData[1].CheckMark === false) {
      toProcessDateData[0].CheckMark = false;
      toProcessDateData[1].CheckMark = true;
      toProcessDateData[2].CheckMark = false;
      toProcessDateData[3].CheckMark = false;
      toProcessDateData[4].CheckMark = false;
    }
    if (idx === 2 && toProcessDateData[2].CheckMark === false) {
      toProcessDateData[0].CheckMark = false;
      toProcessDateData[1].CheckMark = false;
      toProcessDateData[2].CheckMark = true;
      toProcessDateData[3].CheckMark = false;
      toProcessDateData[4].CheckMark = false;
    }
    if (idx === 3 && toProcessDateData[3].CheckMark === false) {
      toProcessDateData[0].CheckMark = false;
      toProcessDateData[1].CheckMark = false;
      toProcessDateData[2].CheckMark = false;
      toProcessDateData[3].CheckMark = true;
      toProcessDateData[4].CheckMark = false;
    }
    if (idx === 4 && toProcessDateData[4].CheckMark === false) {
      toProcessDateData[0].CheckMark = false;
      toProcessDateData[1].CheckMark = false;
      toProcessDateData[2].CheckMark = false;
      toProcessDateData[3].CheckMark = false;
      toProcessDateData[4].CheckMark = true;
    }
    this.setState({PatternList: toProcessDateData});
  }
  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img className= "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <body>
          <div className='PageHeaderMainContainer'>
            <div className='PageHeaderLeftContainer'>
              <div className='PageHeaderLeftBackButton'
                onClick={() => this.props.history.goBack()}>
                {language[this.props.currentLanguage].Back}
              </div>
            </div>
            <div className='PageHeaderMiddleContainer'>
              {language[this.props.currentLanguage].Report}
            </div>
            <div className='PageHeaderRightContainer'>
            </div>
          </div>
          <div className='PageUserSelectnSendContainer'>
            <button className='btIDButton'
              style={{ width: '100%'}}
              onClick={() => this.setUserModalVisible(true)}>
              {this.props.UserForSearch}
              <div className='ReceiptMangeUserText'
                style={{
                  marginRight: 10,
                }}>
                {language[this.props.currentLanguage].Change}
              </div>
            </button>
          </div>
          <div className="BT-body" style={{ height: 'auto'}}>
            <div className='reportSearchDate' style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto'}}>
              <div style={{ textAlign: 'left', marginTop: 5 }}>{language[this.props.currentLanguage].From}</div>
              <div style={{ textAlign: 'left', marginBottom: 5 }}>
                <DatePicker
                  className='inputPicker'
                  onChange={(date) => this.setSelectedDate(date, 'SelectedDateFrom') && this.setSelectedDate(date, 'SelectedDateTo')}
                  placeholder="Date"
                  value={`${moment(this.state.SelectedDateFrom).format('DD/MM/YYYY')} (${language[this.props.currentLanguage].PressToChange})`}
                  withPortal
                  onFocus={(e) => e.target.readOnly = true}
                />
              </div>
            </div>
            <div className='reportSearchDate' style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto'}}>
              <div style={{ textAlign: 'left', marginTop: 5 }}>{language[this.props.currentLanguage].To}</div>
              <div style={{ textAlign: 'left', marginBottom: 5 }}>
                <DatePicker
                  className='inputPicker'
                  onChange={(date) => this.setSelectedDate(date, 'SelectedDateTo')}
                  placeholder="Date"
                  value={`${moment(this.state.SelectedDateTo).format('DD/MM/YYYY')} (${language[this.props.currentLanguage].PressToChange})`}
                  withPortal
                  onFocus={(e) => e.target.readOnly = true}
                />
              </div>
            </div>
            <div style={{ width: '100%',display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '93%', padding: 10}}>
                {this.state.PlatFormList.map((items, index) => {
                  return(
                    <button className='PlatformButtonContainer' 
                      key={index}
                      onClick={() => this.PlatformCheckboxSelect(index)}>
                      <img className="PlatformListIcon" src={items.ImageUri} alt="PlatformLogo"/>
                      {items.CheckMark && (
                        <img className="PlatformListCheckmarkIcon" src={CheckmarkIcon} alt="CheckmarkIcon"/>
                      ) || (<div className='PlatformListCheckmarkIconText'> {items.PlatForm}</div>)}
                    </button>
                  );
                })}
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: 20 }}>
              {this.state.PatternList.map((item, idx) => {
                return(
                  <button key={idx} className="ReportsButton"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}} onClick={() => this.PatternCheckboxSelect(idx)}>
                    <div style={{ color: 'black'}}>{item.Pattern}</div>
                    {item.CheckMark && (
                      <img className="PlatformListCheckmarkIcon" style={{marginRight: 'auto', marginLeft: 'auto'}}  src={CheckmarkIcon} alt="CheckmarkIcon"/>
                    ) || (<div className='PlatformListCheckmarkIconText' style={{marginRight: 'auto', marginLeft: 'auto'}}> {''}</div>)}
                  </button>
                );
              })}
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 25 }}>
              <button className='ReportButtonContainer' onClick={()=> this.onPressCheckReport()}>
                <div className='ReportButtonText' style={{ color: 'black'}}>
                  {language[this.props.currentLanguage].reportCheck}
                </div>
              </button>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5 }}>
              <button className='ReportButtonContainer' onClick={()=> this.onPressTotalBTChecking()}>
                <div className='ReportButtonText' style={{ color: 'black'}}>
                  {language[this.props.currentLanguage].betChecking}
                </div>
              </button>
            </div>
          </div>
          <Modal transparent={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.UserModalVisible}
            contentClassName="br23">
            <Modal.Body className='DateModalBody' style={{ padding: 10}}>
              <div className='DateModalHeader'>
                <div className='DateModalHeaderText'>
                  {language[this.props.currentLanguage].User}
                </div>
                <div className='DateModalHeaderText'>
                  <button className='DateModalCloseButton' onClick={() =>  this.setUserModalVisible(!this.state.UserModalVisible)}> 
                    {language[this.props.currentLanguage].Close}
                  </button>
                </div>
              </div>
              {this.props.UserAndDownlineData.map((item, idx) => {
                return (
                  <button className='dateListButton' 
                    key={idx}
                    onClick={() => this.SelectUserModal(item.ID, item.Username, idx)}>
                    {item.Username}
                  </button>
                )
              })}
            </Modal.Body>
          </Modal>
        </body> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    checkResultdate: state.checkResultdate,
    checkResultPlatForm: state.checkResultPlatForm,
    UserIDForSearch: state.UserIDForSearch,
    UserForSearch: state.UserForSearch,
    currentLanguage: state.currentLanguage,
    UserAndDownlineData: state.UserAndDownlineData,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setCheckReportInfo: (
      SelectedUserID,
      SelectedUser,
      SelectedDateFrom,
      SelectedDateTo,
      UserKeyFormula,
      Pattern,
      Platform,
    ) => {
      dispatch({
        type: 'INFO_FOR_SEARCH',
        payload: {
          UserIDForSearch: SelectedUserID,
          UserForSearch: SelectedUser,
          SearchDateFrom: SelectedDateFrom,
          SearchDateTo: SelectedDateTo,
          SelectedUserKeyFormula: UserKeyFormula,
          redModeToSearch: Pattern,
          PlatformListToSearch: Platform,
        },
      });
    },
    setReportData: ReportData => {
      dispatch({
        type: 'REPORTDATA_FOR_PASS',
        payload: {
          ReportData: ReportData,
        },
      });
    },
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
  };
}
  
  
export default connect(mapStateToProps, mapDispatchToProps)(reportSearch);
  