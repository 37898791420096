import '../App.css';
// import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import ticketLogo from '../img/addOrder.png';
import addOrderSimple from '../img/addOrderSimple.png';
import addOrderRED from '../img/addOrderRED.png';
import OrderManage from '../img/OrderManage.png';
import MoneyReport from '../img/MoneyReport.png';
import InfoIcon from '../img/InfoIcon.png';
import Open from '../img/Open.png';
import setting from '../img/setting.png';
import Modal from 'react-bootstrap/Modal';
import addOrderSGD from '../img/addOrderSGD.png';
import addOrderThai from '../img/addOrderThai.png';
const { getInformation, getUserAndDownline, getSGDRate, getTHAIRate} = require('../Api');

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      informationModal: false,
      information: [],
      AbleRed: '1',
      AbleSGD: '0',
      AbleTHB: '0',
      SGDRate: '',
      THBRate: '',
    }
  }

  async setInfoModalVisible(tf, ID) {
    const CheckVisible = this.state.informationModal;
    if (CheckVisible === true) {
      localStorage.setItem('InformationID',  ID);
      this.setState({informationModal: false});
    } else {
      this.setState({informationModal: true});
    }
  }

  async componentDidMount() {
    const InfoID = localStorage.getItem('InformationID') || '';
    const AbleRed = sessionStorage.getItem('AbleRed') || '';
    const AbleSGD = sessionStorage.getItem('AbleSGD') || '';
    const AbleTHB = sessionStorage.getItem('AbleTHB') || '';
    this.setState({ AbleRed: AbleRed, AbleSGD: AbleSGD, AbleTHB: AbleTHB })
    let SGDRate = 0;
    let ThaiRate = 0;
    if (this.props.AbleSGD === 1) {
      SGDRate = await getSGDRate(this.props.username, this.props.token);
    }
    if (this.props.AbleTHB === 1) {
      ThaiRate = await getTHAIRate(this.props.username, this.props.token);
    }
    const getUserDownlineData = await getUserAndDownline(
      this.props.id,
      this.props.username,
      this.props.token,
    );
 
    const getInfo = await getInformation(
      this.props.username,
      'latest',
      this.props.token,
    );
   
    if (getInfo === 'TimeOut' || getUserDownlineData === 'TimeOut' || SGDRate === 'TimeOut') {
      this.LogoutFunction();
    }
    if (this.props.firstTimeLog === 0) {
      this.props.history.push('/changePassword');
    }
    sessionStorage.setItem('getUserDownlineData', JSON.stringify(getUserDownlineData));
    this.props.setUserDownlineData(getUserDownlineData);
    // getInfo[0].ID.toString() === InfoID.toString() ||
    if ( getInfo.length === 0 || getInfo[0].ID.toString() === InfoID.toString()) {
      this.setState({
        loading: false,
        informationModal: false,
        SGDRate: SGDRate.toFixed(2),
        THBRate: ThaiRate.toFixed(2),
      });
    } else {
      this.setState({
        loading: false,
        information: getInfo,
        informationModal: true,
        SGDRate: SGDRate.toFixed(2),
        THBRate: ThaiRate.toFixed(2),
      });
    }
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img className= "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <div className="MainContainerMenu">
          <div className='PageHeaderMainContainer'>
            <div className='PageHeaderLeftContainer'></div>
            <div className='PageHeaderMiddleContainer'>
              {language[this.props.currentLanguage].MainTitle}
            </div>
            <div className='PageHeaderRightContainer'>
              <div
                className='HeaderLogoutButton'  onClick={() => this.LogoutFunction()}>
                {language[this.props.currentLanguage].Logout}
              </div>
            </div>
          </div>
          <div className="MainContainerMenu">
            {this.props.loginv2 ? <>
              {this.state.AbleSGD === '1' && (
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div className='MenuUser' style={{width: '80%'}}>{`Hi, ${this.props.username}`}</div>
                  <div className='rateText' style={{width: '10%'}}>{`SGD\n ${this.state.SGDRate}`}</div>
                </div>
              )}
              {this.state.AbleSGD !== '1' && (
                <div className='MenuUser' style={{width: '90%'}}>{`Hi, ${this.props.username}`}</div>
              )}
              <div className='MenuBorderDesign'>
                <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/SimpleBT')}>
                  <img className= "MenuIcon" src={addOrderSimple} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].SimpleBT}</div>
                </div><div className='MenuButtonDesign' onClick={()=> this.props.history.push('/receiptManage')}>
                  <img className= "MenuIcon" src={OrderManage} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].TicketManagement}</div>
                </div><div className='MenuButtonDesign' onClick={()=> this.props.history.push('/reportSearch')}>
                  <img className= "MenuIcon" src={MoneyReport} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].Report}</div>
                </div>
              </div>
              <div className='MenuBotoomButtonBorder'>
                <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/Result')}>
                  <img className= "MenuIcon" src={Open} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].DrawResult}</div>
                </div>
                <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/Settings')}>
                  <img className= "MenuIcon" src={setting} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].setting}</div>
                </div>
              </div>
            </> : <>
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div className='MenuUser' style={{width: '70%', alignSelf: 'flex-start'}}>{`Hi, ${this.props.username}`}</div>
                {this.state.AbleSGD  === '1' && (
                  <div className='rateText' style={{width: '10%', marginRight: 10}}>{`SGD\n ${this.state.SGDRate}`}</div>
                )}
                {this.state.AbleTHB  === '1' && (
                  <div className='rateText' style={{width: '10%'}}>{`THB\n ${this.state.THBRate}`}</div>
                )}
              </div>
              {/* <div className='MenuUser' style={{width: '75%', alignSelf: 'flex-start', marginLeft: 15}}>{`Hi, ${this.props.username}`}</div> */}
              <div className='MenuBorderDesign'>
                <div className='MenuButtonDesign'onClick={()=> this.props.history.push('/betting')}>
                  <img className= "MenuIcon" src={ticketLogo} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].Ticket}</div>
                </div>
                {this.state.AbleRed === '1' && 
              <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/bettingRedbill')}>
                <img className= "MenuIcon" src={addOrderRED} alt="BigCo Inc. logo"/>
                <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].RedTicket}</div>
              </div>
                }
                {this.state.AbleSGD === '1' && 
              <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/bettingSGD')}>
                <img className= "MenuIcon" src={addOrderSGD} alt="BigCo Inc. logo"/>
                <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].Ticket} (SGD)</div>
              </div>
                }
                {this.state.AbleTHB === '1' && 
              <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/bettingTHB')}>
                <img className= "MenuIcon" src={addOrderThai} alt="BigCo Inc. logo"/>
                <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].Ticket} (THB)</div>
              </div>
                }
                <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/SimpleBT')}>
                  <img className= "MenuIcon" src={addOrderSimple} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].SimpleBT}</div>
                </div><div className='MenuButtonDesign' onClick={()=> this.props.history.push('/receiptManage')}>
                  <img className= "MenuIcon" src={OrderManage} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].TicketManagement}</div>
                </div><div className='MenuButtonDesign' onClick={()=> this.props.history.push('/reportSearch')}>
                  <img className= "MenuIcon" src={MoneyReport} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].Report}</div>
                </div>
                <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/dailySalesReport')}>
                  <img className= "MenuIcon" src={MoneyReport} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].dailyReport}</div>
                </div>
              </div>
              <div className='MenuBotoomButtonBorder'>
                <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/Result')}>
                  <img className= "MenuIcon" src={Open} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].DrawResult}</div>
                </div>
                <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/information')}>
                  <img className= "MenuIcon" src={InfoIcon} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].Information}</div>
                </div>
                <div className='MenuButtonDesign' onClick={()=> this.props.history.push('/Settings')}>
                  <img className= "MenuIcon" src={setting} alt="BigCo Inc. logo"/>
                  <div className='MenuButtonFontSize'>{language[this.props.currentLanguage].setting}</div>
                </div>
              </div>
            </>}
            <button className="ChinaPressButton"  onClick={()=> window.open("https://www.chinapress.com.my/")}>
              <div className="ChinaPressButtonText">
                {language[this.props.currentLanguage].ChinaPress}
              </div>
            </button>
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.informationModal}
              contentClassName="br23">
              <Modal.Body className='infoModalBody' style={{ padding: 10, height: 500 }}>
                <div className='InformationHeaderContainer'>
                  <p className='InformationText'>{language[this.props.currentLanguage].Information}</p>
                </div>
                {this.state.information.map((item, idx) => {
                  return (
                    <div className='InformationContainer' key={idx}>
                      <div className='InformationDetailsContainer'>
                        {(item.Image !== (null || '') && (
                          <img className="InfoImage" src={item.Image} alt="infoImage"/>
                        )) || <div />}
                        <div className='InformationText' style={{  marginTop: 10, whiteSpace: 'pre-wrap' }}>{item.Information}</div>
                      </div>
                      <button className='InformationFooterContainer' 
                        onClick={() => this.setInfoModalVisible( !this.state.AddNumberModal, item.ID)}
                      >
                        <div className='InformationFooterText'>{language[this.props.currentLanguage].Close}</div>
                      </button>
                    </div>
                  )
                })}
              </Modal.Body>
            </Modal>
          </div>  
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
    firstTimeLog: state.firstTimeLog,
    AbleRed: state.AbleRed,
    loginv2: state.loginv2,
    AbleSGD: state.AbleSGD,
    AbleTHB: state.AbleTHB,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
    setUserDownlineData: UserAndDownlineData => {
      dispatch({
        type: 'USER_DOWNLINE_DATA',
        payload: {
          UserAndDownlineData: UserAndDownlineData,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
  