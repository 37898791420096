/* eslint-disable no-redeclare */
/* eslint-disable no-useless-concat */
import '../App.css';
// import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import BackSpaceIcon from '../img/BackSpaceIcon.png';
import spaceBarIcon from '../img/spaceBarIcon.png';
import deleteIcon from '../img/deleteIcon.png';
import moment from 'moment-timezone';
import {findPermutations} from '../utility/getPermutations';
import {filterStartTag} from '../utility/filterStartTag';
import {processNumPad2Formula} from '../utility/processNumPad2Formula';
import {processPad2KeyAmount} from '../utility/processPad2KeyAmount';
import {processPlatformPosition} from '../utility/processPlatformPosition';
import {processCopyText} from '../utility/processCopyText';
import Modal from 'react-bootstrap/Modal';
import CheckmarkIcon from '../img/Checkmark.png';
import moreIcon from '../img/more.jpg';
import pasteIcon from '../img/paste.png';
import {processEditPasteText} from '../utility/processEditPasteText';
import {processCheckboxPlatformPosition} from '../utility/processCheckboxPlatformPosition';
import {processAC} from '../utility/processAC';
const { CreateBetOrder, getOrderOriginalText, getCashWalletAmt} = require('../Api');

class betting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      UserModalVisible: false,
      modalVisible: false,
      CheckDate: true,
      lineData: [
        {
          originalWord: 'D#',
          translatedWord: 'D#',
          Mode: '',
        },
        {
          originalWord: '',
          translatedWord: '',
          Mode: '',
        },
      ],
      SelectedLine: 0,
      Formula: {},
      OverallAmount: 0,
      // last usage
      WordLine: 0,
      DisplayFormula: '',
      DisplayPlatform: '',
      ModalDate: [{Date: ''}],
      scrollHeight: [],
      UserID: '',
      Username: '',
      DevideOrMultiply: '',
      JingNan: '',
      FunctionModalVisible: false,
      KeyboardType: 'A',
      pad2KeyFormula: [],
      keyboardVisible: true,
      pfPosition: '123456789',
      LastInputAmount: '',
      nameInput: '',
      userBalance: 0,
      editPasteModalVisible: false,
      multilinePasteText: '',
      PlatFormList: [],
    }
    this.NextLineButtonFunction = this.NextLineButtonFunction.bind(this);
    this.PreviosLineButtonFunction = this.PreviosLineButtonFunction.bind(this);
    this.multilinePasteTextHandleText = this.multilinePasteTextHandleText.bind(this);
  }

  async LogoutFunction() {
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('AbleRed');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('FirstTimeLogin');
    sessionStorage.removeItem('Username');
    sessionStorage.removeItem('loginStatus');
    sessionStorage.removeItem('getUserDownlineData');
    sessionStorage.removeItem('ReceiptData');
    sessionStorage.removeItem('UserIDForSearch');
    sessionStorage.removeItem('UserForSearch');
    sessionStorage.removeItem('SearchDateFrom');
    sessionStorage.removeItem('SearchDateTo');
    sessionStorage.removeItem('PatternForSearch');
    sessionStorage.removeItem('PlatformForSearch');
    sessionStorage.removeItem('ReportData');
    this.props.setLoginStatus(false, '', '', '', '');
  }

  async setModalVisible() {
    const CheckVisible = this.state.modalVisible;
    if (CheckVisible === true) {
      this.setState({modalVisible: false});
    } else {
      this.setState({modalVisible: true});
    }
  }

  async setUserModalVisible() {
    const CheckVisible = this.state.UserModalVisible;
    if (CheckVisible === true) {
      this.setState({UserModalVisible: false});
    } else {
      this.setState({UserModalVisible: true});
    }
  }

  async setFunctionModalVisible() {
    const CheckVisible = this.state.FunctionModalVisible;
    if (CheckVisible === true) {
      this.setState({FunctionModalVisible: false});
    } else {
      this.setState({FunctionModalVisible: true});
    }
  }
  
  async setKeyboardVisible() {
    const CheckVisible = this.state.keyboardVisible;
    if (CheckVisible === true) {
      this.setState({keyboardVisible: false});
    } else {
      this.setState({keyboardVisible: true});
    }
  }

  async setPasteEditModalVisible() {
    const CheckVisible = this.state.editPasteModalVisible;
    if (CheckVisible === true) {
      this.setState({editPasteModalVisible: false, multilinePasteText: ''});
    } else {
      this.setState({editPasteModalVisible: true, multilinePasteText: ''});
    }
  }

  GetModalDate() {
    const DateLoop = [];
    const TodayDate = moment().tz('Asia/Kuala_Lumpur').format('LLL');
    const todayTime = moment().tz('Asia/Kuala_Lumpur').format('HHmm');
    if (todayTime >= '1930') {
      for (let i = 1; i <= 8; i++) {
        let Date = moment(TodayDate).add(i, 'days');
        DateLoop.push({
          Date: moment(Date).format('DDMM(ddd)'),
          DateProcess: moment(Date).format('DDMM'),
          CheckMark: false,
        });
      }
    } else {
      for (let i = 0; i <= 7; i++) {
        let Date = moment(TodayDate).add(i, 'days');
        DateLoop.push({
          Date: moment(Date).format('DDMM(ddd)'),
          DateProcess: moment(Date).format('DDMM'),
          CheckMark: false,
        });
      }
    }
    return {DateLoop};
  }

  async SelectUserModal(SelectedID, SelectedUsername, idx) {
    let getUserKeyInResult = [];
    getUserKeyInResult.push({
      KeyIn1: this.props.UserAndDownlineData[idx].KeyIn1,
      KeyIn2: this.props.UserAndDownlineData[idx].KeyIn2,
    });
    let PlatformPosition = processCheckboxPlatformPosition(
      this.props.UserAndDownlineData[idx].GameBetting,
      this.props.UserAndDownlineData[idx].GameDisplay,
    );
    this.setState(
      {
        UserID: SelectedID,
        Username: SelectedUsername,
        UserModalVisible: false,
        Formula: getUserKeyInResult[0],
        DisplayFormula: getUserKeyInResult[0].KeyIn1.toString(),
        DevideOrMultiply: this.props.UserAndDownlineData[idx].MultiplyDivide,
        JingNan: this.props.UserAndDownlineData[idx].JinNan,
        pfPosition: this.props.UserAndDownlineData[idx].GameBetting,
        PlatFormList: PlatformPosition,
        XingJinFormula: this.props.UserAndDownlineData[idx].XingJinFormula,
      },
      () => {
        this.ProcessWords();
        this.CheckUserBalance(SelectedUsername);
      },
    );
  }

  async componentDidMount() {
    this.CheckUserBalance(this.props.username);
    const getKeyType = (await sessionStorage.getItem('CacheKeyBoard')) || 'A';
    const DateLoop = this.GetModalDate();
    let getUserKeyInResult = [];
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    await getUserKeyInResult.push({
      KeyIn1: this.props.UserAndDownlineData[0].KeyIn1,
      KeyIn2: this.props.UserAndDownlineData[0].KeyIn2,
    });
    const processPad2KeyFormula = processNumPad2Formula(
      getUserKeyInResult[0].KeyIn1,
    );
    let PlatformPosition = processCheckboxPlatformPosition(
      this.props.UserAndDownlineData[0].GameBetting,
      this.props.UserAndDownlineData[0].GameDisplay,
    );

    if (this.props.ReBuyData !== '') {
      this.setState({
        pad2KeyFormula: processPad2KeyFormula,
        Formula: getUserKeyInResult[0],
        DisplayFormula: getUserKeyInResult[0].KeyIn1.toString(),
        ModalDate: DateLoop,
        loading: false,
        UserID: this.props.id,
        Username: this.props.username,
        WordLine: this.props.ReBuyData.length - 1,
        DisplayPlatform: this.props.UserAndDownlineData[0].GameDisplay,
        DevideOrMultiply: this.props.UserAndDownlineData[0].MultiplyDivide,
        JingNan: this.props.UserAndDownlineData[0].JinNan,
        SelectedLine: 1,
        KeyboardType: getKeyType,
        pfPosition: this.props.UserAndDownlineData[0].GameBetting,
        PlatFormList: PlatformPosition,
        XingJinFormula: this.props.UserAndDownlineData[0].XingJinFormula,
      });
      this.ProcessWords(this.props.ReBuyData);
      this.props.SetRebuyData('', '', '');
    } else {
      this.setState({
        pad2KeyFormula: processPad2KeyFormula,
        Formula: getUserKeyInResult[0],
        DisplayFormula: getUserKeyInResult[0].KeyIn1.toString(),
        ModalDate: DateLoop,
        loading: false,
        UserID: this.props.id,
        Username: this.props.username,
        DisplayPlatform: this.props.UserAndDownlineData[0].GameDisplay,
        DevideOrMultiply: this.props.UserAndDownlineData[0].MultiplyDivide,
        JingNan: this.props.UserAndDownlineData[0].JinNan,
        SelectedLine: 1,
        WordLine: 1,
        KeyboardType: getKeyType,
        pfPosition: this.props.UserAndDownlineData[0].GameBetting,
        PlatFormList: PlatformPosition,
        XingJinFormula: this.props.UserAndDownlineData[0].XingJinFormula,
      });
    }
  }

  async SelectDateFunction(idx) {
    const toProcessDateData = this.state.ModalDate;
    if (toProcessDateData.DateLoop[idx].CheckMark === false) {
      toProcessDateData.DateLoop[idx].CheckMark = true;
    } else {
      toProcessDateData.DateLoop[idx].CheckMark = false;
    }
    this.setState({ModalDate: toProcessDateData});
  }

  onPressConfirmSelectDate() {
    const toProcessDateData = this.state.ModalDate;
    const lineData = this.state.lineData;
    let DateData = '';
    const CheckDate = toProcessDateData.DateLoop.filter(
      item => item.CheckMark === true,
    );
    if (CheckDate.length > 0) {
      for (let i = 0; i < CheckDate.length; i++) {
        if (i === 0) {
          DateData = 'D#' + '**' + CheckDate[i].DateProcess;
        } else {
          DateData += '*' + CheckDate[i].DateProcess;
        }
      }
      lineData[0].originalWord = DateData;
      this.NextLineButtonFunction('Nextline');
    } else {
      lineData[0].originalWord = 'D#';
      this.NextLineButtonFunction('Nextline');
    }
    this.ProcessWords(lineData);
    this.setState({modalVisible: false});
  }

  async ProcessWords(lineData) {
    const toProcessLineData = lineData || this.state.lineData;
    let numberOfPlatform = 0;
    let OverallAmount = 0;
    let keyInFormulaDisplay = '';
    for (let i = 0; i < toProcessLineData.length; i++) {
      const thisLineWord = toProcessLineData[i].originalWord;
      // process date data
      if (i === 0) {
        toProcessLineData[i].translatedWord = toProcessLineData[i].originalWord;
        if (this.state.SelectedLine !== 0) {
          const {newLineWord} = this.processDate(
            toProcessLineData[i].originalWord,
          );
          toProcessLineData[i].translatedWord = newLineWord;
        }
      } else if (this.state.SelectedLine === i) {
        // this line highlighted
        if (
          thisLineWord.startsWith('#') &&
          thisLineWord.split('#').length === 2
        ) {
          toProcessLineData[i].translatedWord =
            toProcessLineData[i].originalWord;
          toProcessLineData[i].Mode = '';
          keyInFormulaDisplay = this.state.Formula.KeyIn1.toString();
        } else if (thisLineWord.includes('#')) {
          // this line highlighted consist formula
          const {
            newLineWord,
            BLineAmount,
            CalculateLineAmount,
            Mode,
            keyInFormula,
          } = this.processFormula(
            toProcessLineData[i].originalWord,
            numberOfPlatform,
          );
          toProcessLineData[i].translatedWord = newLineWord;
          toProcessLineData[i].BLineAmount = BLineAmount;
          toProcessLineData[i].CalculateLineAmount = CalculateLineAmount;
          toProcessLineData[i].Mode = Mode;
          OverallAmount += CalculateLineAmount;
          keyInFormulaDisplay = keyInFormula.toString();
        } else {
          toProcessLineData[i].translatedWord =
            toProcessLineData[i].originalWord;
          toProcessLineData[i].Mode = '';
          keyInFormulaDisplay = this.state.Formula.KeyIn1.toString();
        }
      } else {
        if (
          thisLineWord.startsWith('#') &&
          thisLineWord.split('#').length === 2 &&
          this.state.JingNan === 1
        ) {
          toProcessLineData[i].Mode = '';
          numberOfPlatform = toProcessLineData[i].originalWord.length - 1;
          let ProcessPlatForm = processPlatformPosition(
            this.state.pfPosition,
            this.state.DisplayPlatform,
            toProcessLineData[i].originalWord,
          );
          ProcessPlatForm = ProcessPlatForm.join().replace(/([,])/g, '');
          toProcessLineData[i].translatedWord = ProcessPlatForm;
        } else if (thisLineWord.includes('#')) {
          const {newLineWord, BLineAmount, CalculateLineAmount, Mode} =
            this.processFormula(
              toProcessLineData[i].originalWord,
              numberOfPlatform,
            );
          toProcessLineData[i].translatedWord = newLineWord;
          toProcessLineData[i].BLineAmount = BLineAmount;
          toProcessLineData[i].CalculateLineAmount = CalculateLineAmount;
          toProcessLineData[i].Mode = Mode;
          OverallAmount += CalculateLineAmount;
        } else if (thisLineWord !== '' && this.state.JingNan === 0) {
          toProcessLineData[i].Mode = '';
          numberOfPlatform = toProcessLineData[i].originalWord.length;
          let ProcessPlatForm = processPlatformPosition(
            this.state.pfPosition,
            this.state.DisplayPlatform,
            toProcessLineData[i].originalWord,
          );
          ProcessPlatForm = ProcessPlatForm.join().replace(/([,])/g, '');
          toProcessLineData[i].translatedWord = ProcessPlatForm;
        }
      }
      const {newLineWordColor} = this.renderWord(
        toProcessLineData[i].translatedWord,
      );
      toProcessLineData[i].translatedWord = newLineWordColor;
    }
    // console.log(toProcessLineData);
    this.setState({
      DisplayFormula: keyInFormulaDisplay,
      lineData: toProcessLineData,
      OverallAmount,
    });
  }

  processDate(originalWord) {
    let toProcessArray = '';
    let newLineWord = '';
    let numberOfday = 1;
    let ProcessDate = '';
    const TodayDate = moment().format('YYMMDD');
    const dateMode = originalWord.substring(2, originalWord.length);
    // toProcessArray = dateMode.split('*');
    if (dateMode === '*') {
      newLineWord = 'D#AUTO';
    } else if (dateMode.startsWith('**')) {
      toProcessArray = dateMode.replace('**', '');
      toProcessArray = toProcessArray.split('*');
      for (let j = 0; j < toProcessArray.length; j += 1) {
        numberOfday = toProcessArray.length;
        if (TodayDate > moment(toProcessArray[j], 'DDMMYY').format('YYMMDD')) {
          ProcessDate += moment(toProcessArray[j], 'DDMMYY').add(1, 'Y').format('DDMM(ddd)');
        } else {
          ProcessDate += moment(toProcessArray[j], 'DDMMYY').format('DDMM(ddd)');
        }
      }
      newLineWord = `D#${numberOfday}Days <Dgreen>${ProcessDate}</Dgreen>`;
      // specific date
    } else if (dateMode.startsWith('*')) {
      let buyDate = [];
      toProcessArray = dateMode.replace('*', '');
      toProcessArray = toProcessArray.split('');
      numberOfday = toProcessArray.length;
      toProcessArray.forEach(item => {
        let toCompareTodayDate = moment().format('YYYYMMDD');
        let toCompareDate = moment().day(item).format('YYYYMMDD');
        let convertDate = moment().day(item).format('DDMM(ddd)');
        if (toCompareDate < toCompareTodayDate) {
          convertDate = moment()
            .day(Number(item) + 7)
            .format('DDMM(ddd)');
        }
        buyDate.push(convertDate);
      });
      // newLineWord = 'D#' + numberOfday + 'Days ' + buyDate;
      newLineWord = `D#${numberOfday}Days <Dgreen>${buyDate}</Dgreen>`;
      // specific day of week
    } else {
      newLineWord = originalWord;
      // next few order
    }
    return {newLineWord};
  }

  processFormula(originalWord, numberOfPlatform) {
    let toProcessArray = '';
    toProcessArray = originalWord.split('#');
    let onlyWord = originalWord.split('#')[0];
    let RemoveDot = onlyWord.replace(/([*])/g, '');
    let newLineWord = '';
    let BLineAmount = 0;
    let CalculateLineAmount = 0;
    let keyInFormula = '';
    let Mode = '';
    let WordQuantity = '';
    let getPermutations = '';
    let subStringWord = '';
    if (
      RemoveDot.length === 4 &&
      onlyWord.length === 4 &&
      toProcessArray[1] &&
      toProcessArray[1].includes('**')
    ) {
      toProcessArray[0] = RemoveDot;
      toProcessArray[1] = toProcessArray[1].replace('**', '');
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          keyInFormula = ['B', 'S', 'A', 'C'];
          WordQuantity = 1;
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.length === 5 &&
      onlyWord.startsWith('*') &&
      toProcessArray[1] &&
      toProcessArray[1].includes('**')
    ) {
      toProcessArray[0] = RemoveDot;
      toProcessArray[1] = toProcessArray[1].replace('**', '');
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          if (this.props.OneStarBao === 0) {
            WordQuantity = 1;
            Mode = 'IB';
          } else {
            getPermutations = findPermutations(RemoveDot);
            WordQuantity = getPermutations.length;
            Mode = 'B';
          }
          keyInFormula = ['B', 'S', 'A', 'C'];
          // WordQuantity = 1;
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.length === 6 &&
      onlyWord.startsWith('**') &&
      toProcessArray[1] &&
      toProcessArray[1].includes('**')
    ) {
      toProcessArray[0] = RemoveDot;
      toProcessArray[1] = toProcessArray[1].replace('**', '');
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          if (this.props.OneStarBao === 1) {
            WordQuantity = 1;
            Mode = 'IB';
          } else {
            getPermutations = findPermutations(RemoveDot);
            WordQuantity = getPermutations.length;
            Mode = 'B';
          }
          keyInFormula = ['B', 'S', 'A', 'C'];
          // WordQuantity = 1;
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (RemoveDot.length === 2 && onlyWord.length === 2) {
      keyInFormula = ['PA', 'PF'];
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.endsWith('***') &&
      onlyWord.length === 5
    ) {
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.endsWith('**') &&
      onlyWord.length === 4
    ) {
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
      WordQuantity = 1;
    } else if (RemoveDot.length === 3 && onlyWord.length === 3) {
      keyInFormula = processAC(this.state.Formula.KeyIn1);
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.endsWith('***') &&
      onlyWord.length === 6
    ) {
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      WordQuantity = 23;
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.endsWith('**') &&
      onlyWord.length === 5
    ) {
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      WordQuantity = 1;
    } else if (RemoveDot.length === 4 && onlyWord.length === 4) {
      keyInFormula = this.state.Formula.KeyIn1;

      WordQuantity = 1;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('***') &&
      onlyWord.length === 7
    ) {
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      WordQuantity = 23;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('**') &&
      onlyWord.length === 6
    ) {
      keyInFormula = this.state.Formula.KeyIn2;
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 3
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = ['PA', 'PF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 6 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 23;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      }
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 5 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 4
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = processAC(this.state.Formula.KeyIn1);
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 23;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      }
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 6 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 4
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = ['PA', 'PF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      } else {
        WordQuantity = 23;
        Mode = 'IB';
      }
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
    } else if (
      RemoveDot.length === 2 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 6 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 5
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = processAC(this.state.Formula.KeyIn1);
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      } else {
        WordQuantity = 23;
        Mode = 'IB';
      }
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
    } else if (
      RemoveDot.length === 3 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 4 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(1, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = processAC(this.state.Formula.KeyIn1);
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 7 &&
      RemoveDot.length === 3 &&
      onlyWord.endsWith('***')
    ) {
      subStringWord = RemoveDot.substring(1, 3);
      getPermutations = findPermutations(subStringWord) * 23;
      WordQuantity = getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 6 &&
      RemoveDot.length === 3 &&
      onlyWord.endsWith('**')
    ) {
      subStringWord = RemoveDot.substring(1, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = '1{234}';
    } else if (
      onlyWord[2] === '*' &&
      onlyWord.length === 4 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(0, 2);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = processAC(this.state.Formula.KeyIn1);
      Mode = '{123}4';
    } else if (
      onlyWord[2] === '*' &&
      onlyWord.length === 7 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(0, 2);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length * 23;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = '{123}4';
    } else if (
      onlyWord[2] === '*' &&
      onlyWord.length === 6 &&
      RemoveDot.length === 3
    ) {
      subStringWord = RemoveDot.substring(0, 2);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      Mode = '{123}4';
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 5
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = this.state.Formula.KeyIn1;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 23;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length * 23;
        Mode = 'B';
      }
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        WordQuantity = 1;
        Mode = 'IB';
      } else {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      }
      keyInFormula = this.state.Formula.KeyIn2;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 6
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = this.state.Formula.KeyIn1;
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 9 &&
      onlyWord.endsWith('***')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 23;
        Mode = 'IB';
      }
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('**')
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
      } else {
        WordQuantity = 1;
        Mode = 'IB';
      }
      keyInFormula = this.state.Formula.KeyIn2;
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 5 &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(1, 4);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn1;
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('***') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(1, 4);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length * 23;
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      Mode = '1{234}';
    } else if (
      onlyWord[1] === '*' &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(1, 4);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn2;
      Mode = '1{234}';
    } else if (
      onlyWord[3] === '*' &&
      onlyWord.length === 5 &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(0, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn1;
      Mode = '{123}4';
    } else if (
      onlyWord[3] === '*' &&
      onlyWord.length === 8 &&
      onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(0, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length * 23;
      keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      Mode = '{123}4';
    } else if (
      onlyWord[3] === '*' &&
      onlyWord.length === 7 &&
      onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      subStringWord = RemoveDot.substring(0, 3);
      getPermutations = findPermutations(subStringWord);
      WordQuantity = getPermutations.length;
      keyInFormula = this.state.Formula.KeyIn2;
      Mode = '{123}4';
    } else if (RemoveDot.includes('-')) {
      // RUN RANGE
      let StartNum = RemoveDot.split('-')[0];
      let ToNum = RemoveDot.substring(RemoveDot.indexOf('-') + 1);
      if (
        StartNum.length === 3 &&
        ToNum.length === 3 &&
        onlyWord.length === 7
      ) {
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = processAC(this.state.Formula.KeyIn1);
        }
      } else if (
        StartNum.length === 3 &&
        ToNum.length === 3 &&
        onlyWord.endsWith('***') &&
        onlyWord.length === 10
      ) {
        WordQuantity = (ToNum - StartNum + 1) * 23;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        }
      } else if (
        StartNum.length === 3 &&
        ToNum.length === 3 &&
        onlyWord.endsWith('**') &&
        onlyWord.length === 9
      ) {
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        }
      } else if (
        StartNum.length === 4 &&
        ToNum.length === 4 &&
        onlyWord.endsWith('***') &&
        onlyWord.length === 12
      ) {
        //WordQuantity = Math.abs(StartNum - ToNum) + 1;
        WordQuantity = (ToNum - StartNum + 1) * 23;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
        }
      } else if (
        StartNum.length === 4 &&
        ToNum.length === 4 &&
        onlyWord.endsWith('**') &&
        onlyWord.length === 11
      ) {
        //WordQuantity = Math.abs(StartNum - ToNum) + 1;
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = this.state.Formula.KeyIn2;
        }
      } else if (
        StartNum.length === 4 &&
        ToNum.length === 4 &&
        !onlyWord.endsWith('**') &&
        onlyWord.length === 9
      ) {
        //WordQuantity = Math.abs(StartNum - ToNum) + 1;
        WordQuantity = ToNum - StartNum + 1;
        if (WordQuantity < 2) {
          alert(
            language[this.props.currentLanguage].RangeBiggerNumber,
          );
        } else {
          Mode = '{1234}*(' + WordQuantity + ')';
          keyInFormula = this.state.Formula.KeyIn1;
        }
      }
    } else if (RemoveDot.length === 5 && onlyWord.length === 5) {
      keyInFormula = ['5D'];
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 5 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 6
    ) {
      if (this.props.OneStarBao === 1) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
        keyInFormula = ['5D'];
      }
    } else if (
      RemoveDot.length === 5 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 7
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
        keyInFormula = ['5D'];
      }
    } else if (RemoveDot.length === 6 && onlyWord.length === 6) {
      keyInFormula = ['6D'];
      WordQuantity = 1;
    } else if (
      RemoveDot.length === 6 &&
      onlyWord.startsWith('*') &&
      onlyWord.length === 7
    ) {
      if (this.props.OneStarBao === 1) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
        keyInFormula = ['6D'];
      }
    } else if (
      RemoveDot.length === 6 &&
      onlyWord.startsWith('**') &&
      onlyWord.length === 8
    ) {
      if (this.props.OneStarBao === 0) {
        getPermutations = findPermutations(RemoveDot);
        WordQuantity = getPermutations.length;
        Mode = 'B';
        keyInFormula = ['6D'];
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('*') &&
      onlyWord.length === 5
    ) {
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          keyInFormula = ['B', 'S', 'A', 'C'];
          WordQuantity = 1;
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('*') &&
      onlyWord.length === 6 &&
      onlyWord.startsWith('*')
    ) {
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          if (this.props.OneStarBao === 0) {
            WordQuantity = 1;
            Mode = 'IB';
          } else {
            getPermutations = findPermutations(RemoveDot);
            WordQuantity = getPermutations.length;
            Mode = 'B';
          }
          keyInFormula = ['B', 'S', 'A', 'C'];
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    } else if (
      RemoveDot.length === 4 &&
      onlyWord.endsWith('*') &&
      onlyWord.length === 7 &&
      onlyWord.startsWith('**')
    ) {
      if (toProcessArray[1]) {
        const XingJinObject = Object.values(
          this.state.XingJinFormula || [],
        ).find(item => item.Num === toProcessArray[1]);
        if (XingJinObject) {
          toProcessArray = [
            onlyWord,
            XingJinObject.B.replace(/([.])/g, '*'),
            XingJinObject.S.replace(/([.])/g, '*'),
            XingJinObject.CA.replace(/([.])/g, '*'),
            XingJinObject.CF.replace(/([.])/g, '*'),
          ];
          if (this.props.OneStarBao === 1) {
            WordQuantity = 1;
            Mode = 'IB';
          } else {
            getPermutations = findPermutations(RemoveDot);
            WordQuantity = getPermutations.length;
            Mode = 'B';
          }
          keyInFormula = ['B', 'S', 'A', 'C'];
        } else {
          keyInFormula = ['error'];
          WordQuantity = 1;
        }
      } else {
        keyInFormula = ['formula'];
        WordQuantity = 1;
      }
    }
    if (originalWord.startsWith('##')) {
      originalWord = originalWord.substring(2, originalWord.length);
      onlyWord = originalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      toProcessArray = originalWord.split('#');
      newLineWord = '##' + newLineWord;
      WordQuantity = 10;
      if (onlyWord.length === 4 && RemoveDot.length === 4) {
        Mode = '123{0-9}';
        keyInFormula = this.state.Formula.KeyIn1;
      } else if (onlyWord.length === 3 && RemoveDot.length === 3) {
        Mode = '123{0-9}';
        keyInFormula = processAC(this.state.Formula.KeyIn1);
      } else if (onlyWord.length === 2 && RemoveDot.length === 2) {
        Mode = '123{0-9}';
        keyInFormula = ['PA', 'PF'];
      } else if (
        onlyWord.length === 6 &&
        RemoveDot.length === 3 &&
        onlyWord.endsWith('***')
      ) {
        WordQuantity = 230;
        Mode = '123{0-9}';
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      } else if (
        onlyWord.length === 5 &&
        RemoveDot.length === 3 &&
        onlyWord.endsWith('**')
      ) {
        Mode = '123{0-9}';
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
      } else if (
        onlyWord.length === 4 &&
        RemoveDot.length === 2 &&
        onlyWord.endsWith('**')
      ) {
        Mode = '123{0-9}';
        keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
      } else if (
        onlyWord.endsWith('***') &&
        onlyWord.length === 7 &&
        RemoveDot.length === 4
      ) {
        Mode = '123{0-9}';
        keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      } else if (
        onlyWord.endsWith('**') &&
        onlyWord.length === 6 &&
        RemoveDot.length === 4
      ) {
        Mode = '123{0-9}';
        keyInFormula = this.state.Formula.KeyIn2;
      }
    } else if (originalWord.startsWith('#')) {
      originalWord = originalWord.substring(1, originalWord.length);
      onlyWord = originalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      toProcessArray = originalWord.split('#');
      newLineWord = '#' + newLineWord;
      WordQuantity = 10;
      if (onlyWord.length === 4 && RemoveDot.length === 4) {
        keyInFormula = this.state.Formula.KeyIn1;
        Mode = '{0-9}123';
      } else if (onlyWord.length === 3 && RemoveDot.length === 3) {
        keyInFormula = processAC(this.state.Formula.KeyIn1);
        Mode = '{0-9}123';
      } else if (onlyWord.length === 2 && RemoveDot.length === 2) {
        keyInFormula = ['PA', 'PF'];
        Mode = '{0-9}123';
      } else if (onlyWord.length === 6 && RemoveDot.length === 3) {
        WordQuantity = 230;
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        Mode = '{0-9}123';
      } else if (onlyWord.length === 5 && RemoveDot.length === 3) {
        keyInFormula = ['CA', 'CB', 'CC', 'CD', 'CE', 'CF'];
        Mode = '{0-9}123';
      } else if (
        onlyWord.endsWith('**') &&
        onlyWord.length === 4 &&
        RemoveDot.length === 2
      ) {
        keyInFormula = ['PA', 'PB', 'PC', 'PD', 'PE', 'PF'];
        Mode = '{0-9}123';
      } else if (
        onlyWord.endsWith('***') &&
        onlyWord.length === 7 &&
        RemoveDot.length === 4
      ) {
        WordQuantity = 10 * 23;
        Mode = '{0-9}123';
        keyInFormula = ['SA', 'SB', 'SC', 'SD', 'SE'];
      } else if (
        onlyWord.endsWith('**') &&
        onlyWord.length === 6 &&
        RemoveDot.length === 4
      ) {
        Mode = '{0-9}123';
        keyInFormula = this.state.Formula.KeyIn2;
      }
    }
    if (
      originalWord.includes('***#') &&
      !onlyWord.endsWith('**') &&
      RemoveDot.length === 4
    ) {
      let FilterFormula1 = [];
      for (let q = 0; q < toProcessArray.length; q += 1) {
        q === 0
          ? toProcessArray[q]
          : FilterFormula1.push(this.state.Formula.KeyIn1[q - 1]);
        if (toProcessArray[q].includes('***')) {
          break;
        }
      }
      let concatFormula = FilterFormula1.concat(this.state.Formula.KeyIn2);
      concatFormula = [...new Set(concatFormula)];
      toProcessArray = originalWord.replace('***', '').split('#');
      keyInFormula = concatFormula;
    }
    if (Mode === 'IB') {
      for (let j = 0; j < toProcessArray.length; j += 1) {
        newLineWord +=
          j === 0
            ? toProcessArray[j]
            : ` <blue>(IB)${keyInFormula[j - 1]}</blue> ${toProcessArray[j]}`;
        if (j !== 0) {
          BLineAmount += Number(toProcessArray[j].replace(/([*])/g, '.'));
          if (this.state.DevideOrMultiply === 'D') {
            if (numberOfPlatform > 0) {
              CalculateLineAmount += toProcessArray[j].replace(/([*])/g, '.') * WordQuantity;
            }
          } else {
            CalculateLineAmount +=
              toProcessArray[j].replace(/([*])/g, '.') * numberOfPlatform * WordQuantity;
          }
        }
      }
    } else {
      for (let j = 0; j < toProcessArray.length; j += 1) {
        newLineWord +=
          j === 0
            ? toProcessArray[j]
            : ` <blue>${keyInFormula[j - 1]}</blue> ${toProcessArray[j]}`;
        if (j !== 0) {
          BLineAmount += Number(toProcessArray[j].replace(/([*])/g, '.'));
          if (this.state.DevideOrMultiply === 'D') {
            if (numberOfPlatform > 0) {
              CalculateLineAmount += toProcessArray[j].replace(/([*])/g, '.') * WordQuantity;
            }
          } else {
            CalculateLineAmount +=
              toProcessArray[j].replace(/([*])/g, '.') * numberOfPlatform * WordQuantity;
          }
        }
      }
    }
    return {newLineWord, BLineAmount, CalculateLineAmount, Mode, keyInFormula};
  }

  PressNumberFunction(PressNumber) {
    let toProcessFormula = this.state.pad2KeyFormula;
    const CheckLength = toProcessFormula.filter(item => item.selected === true);
    const lineData = this.state.lineData;
    let onlyWord = '';
    let CheckOri = lineData[this.state.SelectedLine].originalWord;
    if (CheckOri.startsWith('#')) {
      CheckOri = filterStartTag(CheckOri);
      if (CheckOri.includes('#')) {
        onlyWord = onlyWord.split('#')[0];
        CheckOri = CheckOri.split('#')[0];
        CheckOri = filterStartTag(CheckOri);
      }
    } else {
      onlyWord = filterStartTag(CheckOri);
      onlyWord = onlyWord.split('#')[0];
      CheckOri = filterStartTag(CheckOri);
      CheckOri = CheckOri.split('#')[0];
      CheckOri = filterStartTag(CheckOri);
    }
    CheckOri = CheckOri.replace(/([*])/g, '');
    if (CheckOri.length === 3 && !onlyWord.endsWith('**')) {
      toProcessFormula = toProcessFormula.filter(
        item => item.key === 'A' || item.key === 'C',
      );
      if (toProcessFormula.length === 0) {
        toProcessFormula = ['A', 'C'];
      }
    }
    if (CheckLength.length > 0) {
      this.setState({
        LastInputAmount: this.state.LastInputAmount + PressNumber,
      });
      const processFormulaAmount = processPad2KeyAmount(
        lineData,
        this.state.SelectedLine,
        toProcessFormula,
        this.state.LastInputAmount + PressNumber,
      );
      lineData[this.state.SelectedLine].originalWord = processFormulaAmount;
    } else {
      lineData[this.state.SelectedLine].originalWord =
        lineData[this.state.SelectedLine].originalWord + PressNumber;
    }
    this.ProcessWords(lineData);
    this.flatListRef.scrollToIndex({index: this.state.SelectedLine});
  }
  onPressIBOX() {
    const lineData = this.state.lineData;
    let OriginalWord = lineData[this.state.SelectedLine].originalWord;
    let ProcessWord = '';
    let onlyWord = '';
    let GetFormulaAmount = '';
    let RemoveDot = '';
    if (this.props.OneStarBao === 0) {
      if (!OriginalWord.startsWith('D#') && !OriginalWord.includes('-')) {
        if (!OriginalWord.startsWith('*')) {
          onlyWord = OriginalWord.split('#')[0];
          RemoveDot = onlyWord.replace(/([*])/g, '');
          GetFormulaAmount =
            OriginalWord.substring(OriginalWord.indexOf('#')) || '';
          if (OriginalWord.includes('#') && OriginalWord.startsWith('##')) {
            ProcessWord = '*' + GetFormulaAmount.replace('##', '');
          } else if (
            OriginalWord.includes('#') &&
            OriginalWord.startsWith('#')
          ) {
            ProcessWord = '*' + GetFormulaAmount.replace('#', '');
          } else if (
            OriginalWord.includes('#') &&
            !OriginalWord.startsWith('#') &&
            onlyWord.endsWith('***')
          ) {
            ProcessWord = '*' + RemoveDot + '**' + GetFormulaAmount;
          } else if (
            OriginalWord.includes('#') &&
            !OriginalWord.startsWith('#') &&
            onlyWord.endsWith('**')
          ) {
            ProcessWord = '*' + RemoveDot + '**' + GetFormulaAmount;
          } else if (
            (OriginalWord.startsWith('*') && !OriginalWord.includes('#')) ||
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '*' + RemoveDot + '#';
          } else {
            ProcessWord = '*' + RemoveDot + GetFormulaAmount;
          }
        } else {
          ProcessWord = OriginalWord.replace('*', '');
        }
        lineData[this.state.SelectedLine].originalWord = ProcessWord;
        this.ProcessWords(lineData);
        this.flatListRef.scrollToIndex({index: this.state.SelectedLine});
      }
    } else {
      if (!OriginalWord.startsWith('D#') && !OriginalWord.includes('-')) {
        if (!OriginalWord.startsWith('**')) {
          onlyWord = OriginalWord.split('#')[0];
          RemoveDot = onlyWord.replace(/([*])/g, '');
          GetFormulaAmount =
            OriginalWord.substring(OriginalWord.indexOf('#')) || '';
          if (OriginalWord.includes('#') && OriginalWord.startsWith('##')) {
            ProcessWord = '**' + GetFormulaAmount.replace('##', '');
          } else if (
            OriginalWord.includes('#') &&
            OriginalWord.startsWith('#')
          ) {
            ProcessWord = '**' + GetFormulaAmount.replace('#', '');
          } else if (
            OriginalWord.includes('#') &&
            !OriginalWord.startsWith('#') &&
            onlyWord.endsWith('***')
          ) {
            ProcessWord = '**' + RemoveDot + '***' + GetFormulaAmount;
          } else if (
            OriginalWord.includes('#') &&
            !OriginalWord.startsWith('#') &&
            onlyWord.endsWith('**')
          ) {
            ProcessWord = '**' + RemoveDot + '**' + GetFormulaAmount;
          } else if (
            (OriginalWord.startsWith('*') && !OriginalWord.includes('#')) ||
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '**' + RemoveDot + '#';
          } else {
            ProcessWord = '**' + RemoveDot + GetFormulaAmount;
          }
        } else {
          ProcessWord = OriginalWord.replace('**', '');
        }
        lineData[this.state.SelectedLine].originalWord = ProcessWord;
        this.ProcessWords(lineData);
        this.flatListRef.scrollToIndex({index: this.state.SelectedLine});
      }
    }
  }
  onPressBackspace() {
    let toProcessFormula = this.state.pad2KeyFormula;
    const CheckLength = toProcessFormula.filter(item => item.selected === true);
    const lineData = this.state.lineData;
    if (lineData[this.state.SelectedLine].originalWord !== 'D#') {
      if (CheckLength.length === 0) {
        if (lineData[this.state.SelectedLine].originalWord.endsWith('***#')) {
          lineData[this.state.SelectedLine].originalWord = lineData[
            this.state.SelectedLine
          ].originalWord.slice(0, -4);
        } else {
          lineData[this.state.SelectedLine].originalWord = lineData[
            this.state.SelectedLine
          ].originalWord.slice(0, -1);
        }
      } else {
        let CheckOri = lineData[this.state.SelectedLine].originalWord;
        let onlyWord = '';
        if (CheckOri.startsWith('#')) {
          onlyWord = onlyWord.split('#')[0];
          CheckOri = filterStartTag(CheckOri);
          if (CheckOri.includes('#')) {
            CheckOri = CheckOri.split('#')[0];
            CheckOri = filterStartTag(CheckOri);
          }
        } else {
          onlyWord = filterStartTag(CheckOri);
          onlyWord = onlyWord.split('#')[0];
          CheckOri = filterStartTag(CheckOri);
          CheckOri = CheckOri.split('#')[0];
          CheckOri = filterStartTag(CheckOri);
        }
        CheckOri = CheckOri.replace(/([*])/g, '');
        if (CheckOri.length === 3 && !onlyWord.endsWith('**')) {
          toProcessFormula = toProcessFormula.filter(
            item => item.key === 'A' || item.key === 'C',
          );
          if (toProcessFormula.length === 0) {
            toProcessFormula = ['A', 'C'];
          }
        }
        let LastInput = this.state.LastInputAmount;
        LastInput = LastInput.slice(0, -1);
        if (LastInput.length >= 0 && this.state.LastInputAmount.length > 0) {
          let processFormulaAmount = processPad2KeyAmount(
            lineData,
            this.state.SelectedLine,
            toProcessFormula,
            LastInput,
          );
          this.setState({
            LastInputAmount: LastInput,
          });
          lineData[this.state.SelectedLine].originalWord = processFormulaAmount;
        }
      }
    }
    this.ProcessWords(lineData);
    const SelectedWord = document.getElementById('BetText')
    SelectedWord.focus()
  }
  onPressDot() {
    let toProcessFormula = this.state.pad2KeyFormula;
    const CheckLength = toProcessFormula.filter(item => item.selected === true);
    const lineData = this.state.lineData;
    let CheckOri = lineData[this.state.SelectedLine].originalWord;
    let onlyWord = '';
    if (CheckOri.startsWith('#')) {
      onlyWord = onlyWord.split('#')[0];
      CheckOri = filterStartTag(CheckOri);
      if (CheckOri.includes('#')) {
        CheckOri = CheckOri.split('#')[0];
        CheckOri = filterStartTag(CheckOri);
      }
    } else {
      onlyWord = filterStartTag(CheckOri);
      onlyWord = onlyWord.split('#')[0];
      CheckOri = filterStartTag(CheckOri);
      CheckOri = CheckOri.split('#')[0];
      CheckOri = filterStartTag(CheckOri);
    }
    CheckOri = CheckOri.replace(/([*])/g, '');
    if (CheckOri.length === 3 && !onlyWord.endsWith('**')) {
      toProcessFormula = toProcessFormula.filter(
        item => item.key === 'A' || item.key === 'C',
      );
      if (toProcessFormula.length === 0) {
        toProcessFormula = ['A', 'C'];
      }
    }
    if (CheckLength.length > 0) {
      this.setState({
        LastInputAmount: this.state.LastInputAmount + '*',
      });
      const processFormulaAmount = processPad2KeyAmount(
        lineData,
        this.state.SelectedLine,
        toProcessFormula,
        this.state.LastInputAmount + '*',
      );
      lineData[this.state.SelectedLine].originalWord = processFormulaAmount;
    } else {
      let Dot = '';
      let CheckOri = lineData[this.state.SelectedLine].originalWord;
      CheckOri = filterStartTag(CheckOri);
      CheckOri = CheckOri.split('#');
      if (this.state.SelectedLine === 0) {
        Dot = '*';
      } else if (CheckOri.length > 1) {
        Dot = '*';
      } else {
        Dot = '*';
      }
      lineData[this.state.SelectedLine].originalWord =
        lineData[this.state.SelectedLine].originalWord + Dot;
    }

    this.ProcessWords(lineData);
  }
  onPresstag() {
    const processPad2KeyFormula = processNumPad2Formula(
      this.props.UserAndDownlineData[0].KeyIn1,
    );
    const AllLineData = this.state.lineData;
    const CurrentlineData = this.state.lineData[this.state.SelectedLine];
    let getTagLength = CurrentlineData.originalWord.replace('***', '');
    const FormulaLength = this.state.DisplayFormula.split(',').length;
    if (
      CurrentlineData.originalWord.startsWith('##') &&
      !CurrentlineData.translatedWord.includes('undefined')
    ) {
      getTagLength = getTagLength.replace('##', '');
      getTagLength = getTagLength.replace(/([-*.0-9])/g, '');
    } else if (
      CurrentlineData.originalWord.startsWith('#') &&
      !CurrentlineData.translatedWord.includes('undefined')
    ) {
      getTagLength = getTagLength.replace('#', '');
      getTagLength = getTagLength.replace(/([-*.0-9])/g, '');
    } else {
      getTagLength = getTagLength.replace(/([-*.0-9])/g, '');
    }
    if (getTagLength.length < FormulaLength) {
      if (this.state.SelectedLine !== 0) {
        AllLineData[this.state.SelectedLine].originalWord =
          AllLineData[this.state.SelectedLine].originalWord + '#';
        this.ProcessWords(AllLineData);
        const SelectedWord = document.getElementById('BetText')
        SelectedWord.focus()
      }
    } else if (
      (getTagLength.length > FormulaLength ||
        getTagLength.length === FormulaLength) &&
      !CurrentlineData.translatedWord.includes('undefined')
    ) {
      this.NextLineButtonFunction('Nextline');
    } else if (CurrentlineData.translatedWord.includes('undefined')) {
      alert(language[this.props.currentLanguage].CheckInputCorrect);
    }
    this.setState({
      pad2KeyFormula: processPad2KeyFormula,
      LastInputAmount: '',
    });
  }
  onPressNumberRange() {
    if (this.state.SelectedLine !== 0) {
      const lineData = this.state.lineData;
      let onlyWord =
        lineData[this.state.SelectedLine].originalWord.split('#')[0];
      let RemoveDot = onlyWord.replace(/([*])/g, '');
      if (!lineData[this.state.SelectedLine].originalWord.includes('-')) {
        if (onlyWord.length === 3 && RemoveDot.length === 3) {
          //3D range
          if (!lineData[this.state.SelectedLine].originalWord.includes('#')) {
            lineData[this.state.SelectedLine].originalWord =
              lineData[this.state.SelectedLine].originalWord + '-';
            this.ProcessWords(lineData);
          }
        } else if (
          (onlyWord.length === 4 && RemoveDot.length === 4) ||
          (onlyWord.length === 6 &&
            RemoveDot.length === 4 &&
            onlyWord.endsWith('**'))
        ) {
          if (!lineData[this.state.SelectedLine].originalWord.includes('#')) {
            lineData[this.state.SelectedLine].originalWord =
              lineData[this.state.SelectedLine].originalWord + '-';
            this.ProcessWords(lineData);
          }
        } else {
          alert(
            language[this.props.currentLanguage].OnlyFourDRangeAlert
          );
        }
      } else {
        alert(
          language[this.props.currentLanguage].OnlyFourDRangeAlert
        );
      }
    }
  }
  onPressHit() {
    const lineData = this.state.lineData;
    let OriginalWord = lineData[this.state.SelectedLine].originalWord;
    let ProcessWord = '';
    let onlyWord = '';
    let RemoveDot = '';
    let GetFormulaAmount = '';
    let Key2Sign = '';
    if (!OriginalWord.includes('-')) {
      if (OriginalWord.startsWith('##')) {
        OriginalWord = OriginalWord.substring(2, OriginalWord.length);
      } else if (OriginalWord.startsWith('#')) {
        OriginalWord = OriginalWord.substring(1, OriginalWord.length);
      }
      onlyWord = OriginalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      GetFormulaAmount = OriginalWord.substring(OriginalWord.indexOf('#'));
      if (onlyWord.endsWith('***')) {
        Key2Sign = '***';
      } else if (onlyWord.endsWith('**')) {
        Key2Sign = '**';
      }
      if (this.props.OneStarBao === 0) {
        if (!OriginalWord.startsWith('D#')) {
          if (
            (RemoveDot.length < 2 || RemoveDot.length > 6) &&
            lineData[this.state.SelectedLine].Mode === ''
          ) {
            ProcessWord = OriginalWord;
            alert(
              language[this.props.currentLanguage].OnlyFourDAlert,
            );
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 2 &&
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '**' + RemoveDot + Key2Sign + '#';
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 2 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = '**' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === 'B' &&
            RemoveDot.length === 2 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = '#' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '123{0-9}' &&
            RemoveDot.length === 2 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 3 &&
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '**' + RemoveDot + Key2Sign + '#';
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 3 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = '**' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 4 &&
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '**' + RemoveDot + Key2Sign + '#';
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 4 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = '**' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 4 &&
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '**' + RemoveDot + Key2Sign + '#';
          } else if (lineData[this.state.SelectedLine].Mode === 'IB') {
            ProcessWord = '**' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === 'B' &&
            RemoveDot.length >= 5
          ) {
            ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (lineData[this.state.SelectedLine].Mode === 'B') {
            ProcessWord = '#' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (lineData[this.state.SelectedLine].Mode === '{0-9}123') {
            ProcessWord = '##' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '123{0-9}' &&
            RemoveDot.length === 3
          ) {
            ProcessWord =
              RemoveDot.substring(0, 2) +
              '*' +
              RemoveDot[2] +
              Key2Sign +
              GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '123{0-9}' &&
            RemoveDot.length === 4
          ) {
            ProcessWord =
              RemoveDot.substring(0, 3) +
              '*' +
              RemoveDot[3] +
              Key2Sign +
              GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '{123}4' &&
            RemoveDot.length === 3
          ) {
            ProcessWord =
              RemoveDot[0] +
              '*' +
              RemoveDot.substring(1, 3) +
              Key2Sign +
              GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '{123}4' &&
            RemoveDot.length === 4
          ) {
            ProcessWord =
              RemoveDot[0] +
              '*' +
              RemoveDot.substring(1, 4) +
              Key2Sign +
              GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '1{234}' &&
            RemoveDot.length === 3
          ) {
            ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '1{234}' &&
            RemoveDot.length === 4
          ) {
            ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (RemoveDot.length === 5 && !OriginalWord.includes('#')) {
            ProcessWord = '**' + RemoveDot + Key2Sign + '#';
          } else if (RemoveDot.length === 5) {
            ProcessWord = '**' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (RemoveDot.length === 6 && !OriginalWord.includes('#')) {
            ProcessWord = '**' + RemoveDot + Key2Sign + '#';
          } else if (RemoveDot.length === 6) {
            ProcessWord = '**' + RemoveDot + Key2Sign + GetFormulaAmount;
          }
          lineData[this.state.SelectedLine].originalWord = ProcessWord;
          this.ProcessWords(lineData);
          this.flatListRef.scrollToIndex({index: this.state.SelectedLine});
        }
      } else {
        if (!OriginalWord.startsWith('D#')) {
          if (
            (RemoveDot.length < 2 || RemoveDot.length > 6) &&
            lineData[this.state.SelectedLine].Mode === ''
          ) {
            ProcessWord = OriginalWord;
            alert(
              language[this.props.currentLanguage].OnlyFourDAlert,
            );
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 2 &&
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '*' + RemoveDot + Key2Sign + '#';
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 2 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = '*' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === 'B' &&
            RemoveDot.length === 2 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = '#' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '123{0-9}' &&
            RemoveDot.length === 2 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 3 &&
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '*' + RemoveDot + Key2Sign + '#';
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 3 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = '*' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 4 &&
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '*' + RemoveDot + Key2Sign + '#';
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 4 &&
            OriginalWord.includes('#')
          ) {
            ProcessWord = '*' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '' &&
            RemoveDot.length === 4 &&
            !OriginalWord.includes('#')
          ) {
            ProcessWord = '*' + RemoveDot + Key2Sign + '#';
          } else if (lineData[this.state.SelectedLine].Mode === 'IB') {
            ProcessWord = '*' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === 'B' &&
            RemoveDot.length >= 5
          ) {
            ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (lineData[this.state.SelectedLine].Mode === 'B') {
            ProcessWord = '#' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (lineData[this.state.SelectedLine].Mode === '{0-9}123') {
            ProcessWord = '##' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '123{0-9}' &&
            RemoveDot.length === 3
          ) {
            ProcessWord =
              RemoveDot.substring(0, 2) +
              '*' +
              RemoveDot[2] +
              Key2Sign +
              GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '123{0-9}' &&
            RemoveDot.length === 4
          ) {
            ProcessWord =
              RemoveDot.substring(0, 3) +
              '*' +
              RemoveDot[3] +
              Key2Sign +
              GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '{123}4' &&
            RemoveDot.length === 3
          ) {
            ProcessWord =
              RemoveDot[0] +
              '*' +
              RemoveDot.substring(1, 3) +
              Key2Sign +
              GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '{123}4' &&
            RemoveDot.length === 4
          ) {
            ProcessWord =
              RemoveDot[0] +
              '*' +
              RemoveDot.substring(1, 4) +
              Key2Sign +
              GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '1{234}' &&
            RemoveDot.length === 3
          ) {
            ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (
            lineData[this.state.SelectedLine].Mode === '1{234}' &&
            RemoveDot.length === 4
          ) {
            ProcessWord = RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (RemoveDot.length === 5 && !OriginalWord.includes('#')) {
            ProcessWord = '*' + RemoveDot + Key2Sign + '#';
          } else if (RemoveDot.length === 5) {
            ProcessWord = '*' + RemoveDot + Key2Sign + GetFormulaAmount;
          } else if (RemoveDot.length === 6 && !OriginalWord.includes('#')) {
            ProcessWord = '*' + RemoveDot + Key2Sign + '#';
          } else if (RemoveDot.length === 6) {
            ProcessWord = '*' + RemoveDot + Key2Sign + GetFormulaAmount;
          }
          lineData[this.state.SelectedLine].originalWord = ProcessWord;
          this.ProcessWords(lineData);
          this.flatListRef.scrollToIndex({index: this.state.SelectedLine});
        }
      }
    }
  }
  ReverseNumFunction() {
    const lineData = this.state.lineData;
    let OriginalWord = lineData[this.state.SelectedLine].originalWord;
    const Mode = lineData[this.state.SelectedLine].Mode;
    let onlyWord = '';
    let GetFormulaAmount = '';
    let RemoveDot = '';
    let Key2Sign = '';
    let ReverseWord = '';
    if (this.state.SelectedLine !== 0) {
      if (OriginalWord.startsWith('##')) {
        OriginalWord = OriginalWord.substring(2, OriginalWord.length);
      } else if (OriginalWord.startsWith('#')) {
        OriginalWord = OriginalWord.substring(1, OriginalWord.length);
      }
      onlyWord = OriginalWord.split('#')[0];
      RemoveDot = onlyWord.replace(/([*])/g, '');
      GetFormulaAmount = OriginalWord.substring(OriginalWord.indexOf('#'));
      if (RemoveDot.length === 4 && GetFormulaAmount.includes('#')) {
        if (onlyWord.endsWith('**')) {
          Key2Sign = '**';
        }
        ReverseWord = RemoveDot.split('');
        ReverseWord = ReverseWord.slice()
          .reverse()
          .join()
          .replace(/([,])/g, '');
        this.NextLineButtonFunction('Reverse');
        if (this.props.OneStarBao === 0) {
          if (Mode === 'IB') {
            ReverseWord = '*' + ReverseWord;
          } else if (Mode === 'B') {
            ReverseWord = '**' + ReverseWord;
          } else if (Mode === '{0-9}123') {
            ReverseWord = '#' + ReverseWord;
          } else if (Mode === '123{0-9}') {
            ReverseWord = '##' + ReverseWord;
          } else if (Mode === '{123}4') {
            ReverseWord = ReverseWord.substring(0, 3) + '*' + ReverseWord[3];
          } else if (Mode === '1{234}') {
            ReverseWord = ReverseWord[0] + '*' + ReverseWord.substring(1, 4);
          }
        } else {
          if (Mode === 'IB') {
            ReverseWord = '**' + ReverseWord;
          } else if (Mode === 'B') {
            ReverseWord = '*' + ReverseWord;
          } else if (Mode === '{0-9}123') {
            ReverseWord = '#' + ReverseWord;
          } else if (Mode === '123{0-9}') {
            ReverseWord = '##' + ReverseWord;
          } else if (Mode === '{123}4') {
            ReverseWord = ReverseWord.substring(0, 3) + '*' + ReverseWord[3];
          } else if (Mode === '1{234}') {
            ReverseWord = ReverseWord[0] + '*' + ReverseWord.substring(1, 4);
          }
        }
        lineData[this.state.WordLine + 1].originalWord =
          ReverseWord + Key2Sign + GetFormulaAmount;
      } else {
        alert(language[this.props.currentLanguage].OnlyFourDAlert);
      }
      this.ProcessWords(lineData);
    } else {
      alert(language[this.props.currentLanguage].OnlyFourDAlert);
    }
  }
  async SendButtonFunction() {
    this.setState({loading: true});
    const lineData = this.state.lineData;
    const OriwordArray = [];
    let ErrorLine = [];
    let Platformvalidate = false;
    let BetWordValidate = false;
    for (var i = 0; i < lineData.length; i++) {
      let CheckAmountNull = lineData[i].BLineAmount;
      for (var u = 0; u < lineData[i].translatedWord.length; u++) {
        if (
          JSON.stringify(lineData[i].translatedWord[u]).includes('undefined')
        ) {
          ErrorLine.push(i);
        }
      }
      if (lineData[i].originalWord.includes('D#')) {
        OriwordArray.push(lineData[i].originalWord + '\n');
      } else if (
        !isNaN(lineData[i].originalWord) === true &&
        lineData[i].originalWord.length > 9
      ) {
        //phn number ignore err
        OriwordArray.push(lineData[i].originalWord + '\n');
      } else if (
        lineData[i].originalWord.startsWith('#') &&
        lineData[i].originalWord.split('#').length === 2
      ) {
        // JingNan Platform
        let OriWord = lineData[i].originalWord.split('');
        let PlatFormFilter = lineData[i].originalWord;
        PlatFormFilter = [...new Set(PlatFormFilter)];
        if (
          OriWord.length !== PlatFormFilter.length ||
          lineData[i].originalWord.includes(0) ||
          lineData[i].originalWord.includes('*')
        ) {
          ErrorLine.push(i);
        } else {
          OriwordArray.push(lineData[i].originalWord + '\n');
        }
      } else if (
        lineData[i].originalWord.includes('-') &&
        !lineData[i].originalWord.includes('#')
      ) {
        ErrorLine.push(i);
      } 
      // else if (
      //   lineData[i].originalWord.includes('#') &&
      //   lineData[i].originalWord !== '' &&
      //   CheckAmountNull !== ''
      // ) {
      //   if (!isNaN(Number(lineData[i].BLineAmount)) === true) {
      //     let ConvertDot = lineData[i].originalWord
      //       .replace('...', '***')
      //       .replace('..', '**');
      //     OriwordArray.push(ConvertDot + '\n');
      //   } else {
      //     ErrorLine.push(i);
      //   }
      // } 
      else if (
        lineData[i].originalWord !== '' &&
        lineData[i].originalWord.includes('#') &&
        lineData[i].BLineAmount > 0
      ) {
        OriwordArray.push(lineData[i].originalWord + '\n');
      } else {
        OriwordArray.push(lineData[i].originalWord + '\n');
      }
    }
    for (var a = 0; a < OriwordArray.length; a++) {
      if (a !== 0) {
        //if No Platform At All
        if (
          this.state.JingNan === 1 &&
          OriwordArray[1].split('#').length === 2
        ) {
          Platformvalidate = true;
        } else if (!OriwordArray[1].includes('#')) {
          Platformvalidate = true;
        }
        //if No Amount At All
        if (this.state.JingNan === 1) {
          BetWordValidate = true;
        } else if (
          this.state.JingNan === 0 &&
          OriwordArray[a].includes('#') === true
        ) {
          BetWordValidate = true;
        }
      }
    }

    if (ErrorLine.length > 0) {
      alert(
        language[this.props.currentLanguage].LineErr + ErrorLine,
      );
    } else if (Platformvalidate === false) {
      alert(
        language[this.props.currentLanguage].BTNoPlatForm,
      );
    } else if (BetWordValidate === false) {
      alert(
        language[this.props.currentLanguage].BTNoAmount,
      );
    } else {
      const OrderReceiptData = await CreateBetOrder(
        this.state.UserID,
        OriwordArray.join(''),
        this.props.token,
        this.props.username,
        '',
        '',
        this.state.nameInput,
        'mobile',
      );
      if (OrderReceiptData.error === 'User No Package') {
        alert(
          language[this.props.currentLanguage].NoBetPackage,
        );
      } else if (OrderReceiptData.error === 'No Date to Bet') {
        alert(
          language[this.props.currentLanguage].NoDateBet,
        );
      } else if (OrderReceiptData.error === '5D6D in wrong game') {
        alert(
          language[this.props.currentLanguage].Wrong5D6D,
        );
      } else if (OrderReceiptData.error === 'Date error') {
        alert(
          language[this.props.currentLanguage].BuyDateWarn,
        );
      } else if (OrderReceiptData.error === 'Not enough date') {
        alert(
          language[this.props.currentLanguage].NoEnufDate,
        );
      } else if (OrderReceiptData.error === 'Game ShutDown') {
        alert(
          language[this.props.currentLanguage].GameShutDown,
        );
      } else if (OrderReceiptData.error === 'S Game ShutDown') {
        alert(
          language[this.props.currentLanguage].SGameShutDown,
        );
      } else if (OrderReceiptData.error === 'No Game on that day') {
        alert(
          language[this.props.currentLanguage].DateNoAvailable,
        );
      } else if (OrderReceiptData.error === 'Platform error') {
        alert(
          language[this.props.currentLanguage].PlatFormErr,
        );
      } else if (OrderReceiptData.error === 'Account Inactive') {
        alert(
          language[this.props.currentLanguage].AccountInactive,
        );
      } else if (OrderReceiptData.error === 'Exceed Personal Daily Limit') {
        alert(
          language[this.props.currentLanguage].ExceedPerLimit,
        );
      } else if (OrderReceiptData.error === 'User Red Lower Than Commission') {
        alert(
          'User Red Lower Than Commission',
        );
      } else if (OrderReceiptData.error === 'MKTSWBD ShutDown') {
        alert(
          language[this.props.currentLanguage].MKTSWBDoff,
        );
      } else if (OrderReceiptData.error === 'All Game ShutDown') {
        alert(
          language[this.props.currentLanguage].AllGameoff,
        );
      } else if (OrderReceiptData.error === 'Repeat Order') {
        alert(language[this.props.currentLanguage].RepeatOrder,);
      } else if (OrderReceiptData.error === 'Over Number Limit') {
        alert(
          language[this.props.currentLanguage].OverNumberLimit,
        );
      } else if (OrderReceiptData.error === 'Format Error') {
        alert(language[this.props.currentLanguage].FormatError);
      } else if (OrderReceiptData.error === 'User Negative Commission') {
        alert(
          language[this.props.currentLanguage].UserNegativeCommission,
        );
      } else if (OrderReceiptData.error === 'Cannot bet game desired') {
        alert(
          language[this.props.currentLanguage].GameDesired,
        );
      } else if (OrderReceiptData.error === 'Not enough cash wallet') {
        alert(
          'Balance:' +
            OrderReceiptData.balance +
            '\n' +
          language[this.props.currentLanguage].noEnufCashWall,
        );
      } else if (OrderReceiptData.error) {
        alert(
          OrderReceiptData.error,
        );
      } else if (OrderReceiptData === 'TimeOut') {
        this.LogoutFunction();
      } else {
        // console.log(OrderReceiptData);
        // sessionStorage.setItem('ReceiptData', JSON.stringify(OrderReceiptData));
        this.props.setReceiptData(OrderReceiptData, '');
        this.props.history.push('/receiptAfterSubmit');
      }
    }
    this.setState({loading: false});
  }

  SelectLineFunction(idx) {
    const processPad2KeyFormula = processNumPad2Formula(
      this.props.UserAndDownlineData[0].KeyIn1,
    );
    let Line = idx.Line;
    let Word = this.state.lineData;
    if (Line === 0) {
      this.setState(
        {
          SelectedLine: Line,
          pad2KeyFormula: processPad2KeyFormula,
          keyboardVisible: true,
          LastInputAmount: '',
        },
        () => setTimeout(() => {
          this.ProcessWords(Word)
          this.setModalVisible(true)
          const SelectedWord = document.getElementById('BetText')
          SelectedWord.focus()
        }, 20),
      );
    } else {
      this.setState(
        {
          SelectedLine: Line,
          pad2KeyFormula: processPad2KeyFormula,
          keyboardVisible: true,
          LastInputAmount: '',
        },  
        () => setTimeout(() => {
          this.ProcessWords(Word)
          const SelectedWord = document.getElementById('BetText')
          SelectedWord.focus()
        }, 20),
      );
    }
  }
  PreviosLineButtonFunction() {
    const processPad2KeyFormula = processNumPad2Formula(
      this.props.UserAndDownlineData[0].KeyIn1,
    );
    let addOneNewLine = this.state.lineData;
    if (this.state.SelectedLine > 0) {
      this.setState(
        {
          SelectedLine: this.state.SelectedLine - 1,
          LastInputAmount: '',
          pad2KeyFormula: processPad2KeyFormula,
        },
        () => setTimeout(() => {
          this.ProcessWords(addOneNewLine)
          const SelectedWord = document.getElementById('BetText')
          SelectedWord.focus()
        }, 20),
      );
    }
  }

  async NextLineButtonFunction(Hint) {
    const processPad2KeyFormula = processNumPad2Formula(
      this.props.UserAndDownlineData[0].KeyIn1,
    );
    let originalWord =
      this.state.lineData[this.state.SelectedLine].originalWord;
    let addOneNewLine = this.state.lineData;
    if (
      this.state.SelectedLine === 0 &&
      this.state.lineData[0].originalWord.includes('D#1*')
    ) {
      let ReceiptID = this.state.lineData[0].originalWord.split('D#1*');
      ReceiptID = ReceiptID[1].split('*');
      this.setState({loading: true});
      const getReceipt = await getOrderOriginalText(
        this.state.UserID,
        ReceiptID[0],
        this.props.username,
        this.props.token,
      );
      if (getReceipt.error === 'Invalid UserOrderID!') {
        this.setState({loading: false});
        // this.onpressClearAll();
        alert('No Receipt');
      } else if (getReceipt.error === 'Invalid credentials!') {
        this.setState({loading: false});
        alert('Invalid credentials');
      } else if (getReceipt.error) {
        this.setState({loading: false});
        alert(getReceipt.error);
      } else if (getReceipt === 'TimeOut') {
        this.LogoutFunction();
      } else {
        let ToprocessOriData = getReceipt.originalText.split('\n');
        let toProcessLineData = [];
        for (let i = 0; i < ToprocessOriData.length; i++) {
          if (i === 0) {
            toProcessLineData.push({
              originalWord: this.state.lineData[0].originalWord,
              translatedWord: '',
              Mode: '',
            });
          } else {
            toProcessLineData.push({
              originalWord: ToprocessOriData[i],
              translatedWord: '',
              Mode: '',
            });
          }
        }
        this.setState(
          {
            loading: false,
            SelectedLine: this.state.SelectedLine + 1,
            WordLine: this.state.WordLine + 1,
            pad2KeyFormula: processPad2KeyFormula,
            LastInputAmount: '',
          },
          () => this.ProcessWords(toProcessLineData),
        );
      }
    } else {
      if (
        (this.state.lineData.length <= this.state.SelectedLine + 1 &&
          this.state.lineData[this.state.WordLine].originalWord !== '') ||
        Hint === 'Reverse'
      ) {
        addOneNewLine.push({
          originalWord: '',
          translatedWord: '',
          CalculateLineAmount: '',
          Mode: '',
        });
      }
      if (Hint === 'NextlineForNewLine') {
        this.setState(
          {
            SelectedLine: this.state.SelectedLine + 1,
            WordLine: this.state.WordLine + 1,
            pad2KeyFormula: processPad2KeyFormula,
            LastInputAmount: '',
          },
          () => 
            setTimeout(() => {
              this.ProcessWords(addOneNewLine)
              const SelectedWord = document.getElementById('BetText')
              SelectedWord.focus()
            }, 20),
        );
      } else if (Hint === 'Nextline') {
        if (this.state.WordLine === this.state.SelectedLine) {
          if (originalWord !== '') {
            this.CopyAmount(originalWord, addOneNewLine);
            this.setState(
              {
                SelectedLine: this.state.SelectedLine + 1,
                WordLine: this.state.WordLine + 1,
                pad2KeyFormula: processPad2KeyFormula,
                LastInputAmount: '',
              },
              () => 
                setTimeout(() => {
                  this.ProcessWords(addOneNewLine)
                  const SelectedWord = document.getElementById('BetText')
                  SelectedWord.focus()
                }, 20),
            );
          }
        } else {
          this.CopyAmount(originalWord, addOneNewLine);
          this.setState(
            {
              SelectedLine: this.state.SelectedLine + 1,
              pad2KeyFormula: processPad2KeyFormula,
              LastInputAmount: '',
            },
            () => setTimeout(() => {
              this.ProcessWords(addOneNewLine)
              const SelectedWord = document.getElementById('BetText')
              SelectedWord.focus()
            }, 20),
          );
        }
      } else if (Hint === 'Reverse') {
        this.setState(
          {
            WordLine: this.state.WordLine + 1,
            SelectedLine: this.state.WordLine + 1,
            pad2KeyFormula: processPad2KeyFormula,
          },
          () => setTimeout(() => {
            this.ProcessWords(addOneNewLine)
            const SelectedWord = document.getElementById('BetText')
            SelectedWord.focus()
          }, 20),
        );
      }
    }
  }

  async onpressClearAll() {
    const processPad2KeyFormula = processNumPad2Formula(
      this.props.UserAndDownlineData[0].KeyIn1,
    );
    if (window.confirm(language[this.props.currentLanguage].ClearAllConfirmation)) {
      this.setState({
        pad2KeyFormula: processPad2KeyFormula,
        lineData: [
          {
            originalWord: 'D#',
            translatedWord: 'D#',
            Mode: '',
          },
          {
            originalWord: '',
            translatedWord: '',
            Mode: '',
          },
        ],
        SelectedLine: 1,
        OverallAmount: 0,
        WordLine: 1,
        LastInputAmount: '',
      });
    }
  }
  async SelectedFunction(selectedFun) {
    if (
      selectedFun === language[this.props.currentLanguage].ClearAllAlertTitle
    ) {
      this.onpressClearAll();
      this.setState({FunctionModalVisible: false});
    } else if (selectedFun === language[this.props.currentLanguage].Paste) {
      this.onPressPaste();
      this.setState({FunctionModalVisible: false});
    } else if (selectedFun === language[this.props.currentLanguage].editPaste) {
      this.setState({editPasteModalVisible: true, FunctionModalVisible: false});
    } else if (
      selectedFun === language[this.props.currentLanguage].AddNewLine
    ) {
      const addSPACE = {
        originalWord: '',
        translatedWord: '',
        Mode: '',
      };
      this.state.lineData.splice(this.state.SelectedLine + 1, 0, addSPACE);
      this.NextLineButtonFunction('NextlineForNewLine');
      this.ProcessWords();
      this.setState({FunctionModalVisible: false});
    }
  }

  async ClearRow() {
    const lineData = this.state.lineData;
    let OriginalWord = lineData[this.state.SelectedLine].originalWord;
    if (!OriginalWord.startsWith('D#')) {
      const processPad2KeyFormula = processNumPad2Formula(
        this.props.UserAndDownlineData[0].KeyIn1,
      );
      lineData[this.state.SelectedLine].originalWord = '';
      lineData[this.state.SelectedLine].BLineAmount = 0;
      this.ProcessWords(lineData);
      this.setState({
        pad2KeyFormula: processPad2KeyFormula,
        LastInputAmount: '',
      });
      const SelectedWord = document.getElementById('BetText')
      SelectedWord.focus()
    }
  }

  async CopyAmount(originalWord, addOneNewLine) {
    let AmountCopy = '';
    let Key2Sign = '';
    let onlyWord = '';
    if (
      this.state.JingNan === 1 &&
      originalWord.split('#').length < 2 &&
      this.state.SelectedLine !== 0 &&
      originalWord.length >= 2
    ) {
      // RUN JINNAN
      // Loop Check Format
      for (var i = this.state.SelectedLine; i >= 0; i--) {
        if (
          addOneNewLine[i].originalWord.split('#').length === 2 &&
          i !== 0 &&
          addOneNewLine[i].originalWord.split('#')[0] === ''
        ) {
          if (addOneNewLine[i].originalWord) {
            //Copy Amount
            for (var x = this.state.SelectedLine; x >= 0; x--) {
              if (addOneNewLine[x].BLineAmount > 0) {
                let AmountOriWord = addOneNewLine[x].originalWord;
                let toCheckEndStart = '';
                if (AmountOriWord.startsWith('##')) {
                  AmountOriWord = AmountOriWord.replace('##', '');
                  AmountCopy = AmountOriWord.substring(
                    AmountOriWord.indexOf('#'),
                  );
                  toCheckEndStart = AmountOriWord.split('#')[0];
                  onlyWord = addOneNewLine[x].originalWord.split('#')[0];
                } else if (AmountOriWord.startsWith('#')) {
                  AmountOriWord = AmountOriWord.replace('#', '');
                  AmountCopy = AmountOriWord.substring(
                    AmountOriWord.indexOf('#'),
                  );
                  toCheckEndStart = AmountOriWord.split('#')[0];
                  onlyWord = addOneNewLine[x].originalWord.split('#')[0];
                } else {
                  AmountCopy = AmountOriWord.substring(
                    AmountOriWord.indexOf('#'),
                  );
                  toCheckEndStart = AmountOriWord.split('#')[0];
                  onlyWord = addOneNewLine[x].originalWord.split('#')[0];
                }
                if (originalWord.endsWith('**#') || originalWord.endsWith('***#')) {
                  Key2Sign = '';
                } else if (onlyWord.endsWith('***')) {
                  Key2Sign = '***';
                } else if (onlyWord.endsWith('**')) {
                  Key2Sign = '**';
                } else {
                  Key2Sign = '';
                }
                if (
                  toCheckEndStart.endsWith('*') &&
                  !toCheckEndStart.endsWith('**')
                ) {
                  addOneNewLine[this.state.SelectedLine].originalWord =
                    originalWord + Key2Sign + '*' + AmountCopy;
                } else {
                  addOneNewLine[this.state.SelectedLine].originalWord =
                    originalWord + Key2Sign + AmountCopy;
                }
                break;
              }
            }
          }
          break;
        }
      }
    } else if (
      this.state.JingNan === 0 &&
      this.state.SelectedLine !== 0 &&
      addOneNewLine[this.state.SelectedLine].BLineAmount <= 0 &&
      originalWord.includes('#') &&
      originalWord.length >= 2 &&
      originalWord.endsWith('#') &&
      !originalWord.endsWith('##') &&
      !addOneNewLine[this.state.SelectedLine].translatedWord.includes(
        'undefined',
      )
    ) {
      for (var i = this.state.SelectedLine; i >= 0; i--) {
        if (addOneNewLine[i].BLineAmount > 0) {
          let AmountOriWord = addOneNewLine[i].originalWord;
          let toCheckEndStart = '';
          if (AmountOriWord.startsWith('##')) {
            AmountOriWord = AmountOriWord.replace('##', '');
            AmountCopy = AmountOriWord.substring(
              AmountOriWord.indexOf('#') + 1,
            );
            toCheckEndStart = AmountOriWord.split('#')[0];
            onlyWord = addOneNewLine[i].originalWord.split('#')[0];
          } else if (AmountOriWord.startsWith('#')) {
            AmountOriWord = AmountOriWord.replace('#', '');
            AmountCopy = AmountOriWord.substring(
              AmountOriWord.indexOf('#') + 1,
            );
            toCheckEndStart = AmountOriWord.split('#')[0];
            onlyWord = addOneNewLine[i].originalWord.split('#')[0];
          } else {
            AmountCopy = AmountOriWord.substring(
              AmountOriWord.indexOf('#') + 1,
            );
            toCheckEndStart = AmountOriWord.split('#')[0];
            onlyWord = addOneNewLine[i].originalWord.split('#')[0];
          }
          if (originalWord.endsWith('**') || originalWord.endsWith('***')) {
            Key2Sign = '';
          } else if (onlyWord.endsWith('***')) {
            Key2Sign = '***';
          } else if (onlyWord.endsWith('**')) {
            Key2Sign = '**';
          } else {
            Key2Sign = '';
          }
          if (
            toCheckEndStart.endsWith('*') &&
            !toCheckEndStart.endsWith('**')
          ) {
            addOneNewLine[this.state.SelectedLine].originalWord =
              originalWord.slice(0, -1) + Key2Sign + '*#' + AmountCopy;
          } else {
            addOneNewLine[this.state.SelectedLine].originalWord =
              originalWord.slice(0, -1) + Key2Sign + '#' + AmountCopy;
          }
          break;
        }
      }
    }
  }
  onpressLongTerm() {
    const lineData = this.state.lineData;
    lineData[0].originalWord = 'D#*';
    this.ProcessWords(lineData);
    this.setState({modalVisible: false});
    this.NextLineButtonFunction('Nextline');
  }
  onpressPeriod(Num) {
    const lineData = this.state.lineData;
    lineData[0].originalWord = 'D#' + Num;
    this.ProcessWords(lineData);
    this.setState({modalVisible: false});
    this.NextLineButtonFunction('Nextline');
  }

  renderWord(newLineWordColor) {
    if (newLineWordColor.includes('<green>')) {
      let returnWord = [];
      const WordArray = newLineWordColor
        .split('<green>')
        .join('</green>')
        .split('</green>');
      for (let x = 0; x < WordArray.length; x += 1) {
        if (x === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        } else if (x % 2 === 1) {
          returnWord.push(
            <div
              key={x}
              style={{
                color: '#0d5922',
                fontSize: 19,
                fontWeight: 'bold',
                letterSpacing: 5,
              }}>
              {WordArray[x]}
            </div>,
          );
        } else if (x % 2 === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        }
      }
      newLineWordColor = returnWord;
    } else if (newLineWordColor.includes('<blue>')) {
      let returnWord = [];
      const WordArray = newLineWordColor
        .split('<blue>')
        .join('</blue>')
        .split('</blue>');
      for (let x = 0; x < WordArray.length; x += 1) {
        if (x === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        } else if (x % 2 === 1) {
          returnWord.push(
            <div
              key={x}
              style={{
                color: 'blue',
                fontSize: 19,
                fontWeight: 'bold',
                marginRight: 5,
                marginLeft: 5,
              }}>
              {WordArray[x]}
            </div>,
          );
        } else if (x % 2 === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        }
      }
      newLineWordColor = returnWord;
    } else if (newLineWordColor.includes('<Dgreen>')) {
      let returnWord = [];
      const WordArray = newLineWordColor
        .split('<Dgreen>')
        .join('</Dgreen>')
        .split('</Dgreen>');
      for (let x = 0; x < WordArray.length; x += 1) {
        if (x === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        } else if (x % 2 === 1) {
          returnWord.push(
            <div
              key={x}
              style={{
                color: '#005c08',
                fontSize: 19,
                fontWeight: 'bold',
              }}>
              {WordArray[x]}
            </div>,
          );
        } else if (x % 2 === 0) {
          returnWord.push(<div key={x}>{WordArray[x]}</div>);
        }
      }
      newLineWordColor = returnWord;
    }
    return {newLineWordColor};
  }

  async onPressPaste() {
    try {
      navigator.clipboard.readText();
    } catch {
      alert(
        language[this.props.currentLanguage].FailedPaste,
      );
    }
    const getCopyText = await navigator.clipboard.readText();
    // const getCopyText = `D#*2
    // *2
    // 1...
    // ##1234#1#1#1\n
    // 123#2
    // 6625#1#1
    // **5567##1
    // D#3
    // 6655-6666#1
    // 1236#+++---/
    // 1233#`;
    const getfilterArray = processCopyText(getCopyText.split('\n'));
    if (window.confirm(getfilterArray.toString().replace(/,/g, '\n'))) {
      this.pasteFunction(getfilterArray);
    } else {
      this.state.lineData[1].originalWord = '';
    }
  }

  pasteFunction(getfilterArray) {
    let toProcessLineData = this.state.lineData;
    for (let i = 0; i < getfilterArray.length; i++) {
      if (getfilterArray[i].startsWith('D#') && i === 0) {
        toProcessLineData[0].originalWord = getfilterArray[i];
      } else if (getfilterArray[i].includes('D#') && i !== 1) {
        // ignore
      } else if (
        toProcessLineData[1].originalWord === '' &&
        !getfilterArray[i].includes('D') && 
        this.state.WordLine <= 1
      ) {
        toProcessLineData[1].originalWord = getfilterArray[i];
      }  else {
        toProcessLineData.push({
          originalWord: getfilterArray[i],
          translatedWord: '',
          Mode: '',
        });
      }
    }
    this.setState({
      WordLine: toProcessLineData.length - 1,
      SelectedLine: toProcessLineData.length - 1,
      multilinePasteText: '',
    });
    this.ProcessWords(toProcessLineData);
  }

  async Str8PasteFuntion() {
    const getCopyText = await navigator.clipboard.readText();
    const getfilterArray = processCopyText(getCopyText.split('\n'));
    getfilterArray.toString().replace(/,/g, '\n');
    let toProcessLineData = this.state.lineData;
    for (let i = 0; i < getfilterArray.length; i++) {
      if (getfilterArray[i].startsWith('D#') && i === 0) {
        toProcessLineData[0].originalWord = getfilterArray[i];
      } else if (getfilterArray[i].includes('D#') && i !== 0) {
        // ignore
      } else if (
        toProcessLineData[1].originalWord === '' &&
        !getfilterArray[i].includes('D') &&
        this.state.WordLine <= 1
      ) {
        toProcessLineData[1].originalWord = getfilterArray[i];
      } else {
        toProcessLineData.push({
          originalWord: getfilterArray[i],
          translatedWord: getfilterArray[i],
          Mode: '',
        });
      }
    }
    this.setState({
      WordLine: toProcessLineData.length - 1,
      SelectedLine: toProcessLineData.length - 1,
      multilinePasteText: '',
    });
    this.ProcessWords(toProcessLineData);
  }

  async Pad2PressFormula(idx, InputKey) {
    const lineData = this.state.lineData;
    let FormulaNum = idx + 1;
    let NumberTag = '';
    let CheckOri = lineData[this.state.SelectedLine].originalWord;
    let CheckTag = '';
    let keyInFormula = '';
    let onlyWord = '';
    const CheckAC = this.state.Formula.KeyIn1.filter(
      item => item === 'A' || item === 'C',
    );
    if (CheckOri.startsWith('#')) {
      onlyWord = filterStartTag(CheckOri);
      if (onlyWord.includes('#')) {
        onlyWord = onlyWord.split('#')[0];
        CheckOri = filterStartTag(CheckOri);
        CheckTag = CheckOri.replace(/[^#]/g, '');
      }
    } else {
      onlyWord = filterStartTag(CheckOri);
      onlyWord = onlyWord.split('#')[0];
      CheckOri = filterStartTag(CheckOri);
      CheckTag = CheckOri.replace(/[^#]/g, '');
    }
    let RemoveDot = onlyWord.replace(/([*])/g, '');
    const toProcessFormula = this.state.pad2KeyFormula;
    keyInFormula = toProcessFormula.filter(item => item.selected === true);
    if (RemoveDot.length < 3 || RemoveDot.length > 4) {
      alert(
        language[this.props.currentLanguage].only3n4D,
      );
    } else if (CheckTag.length > 5) {
      alert(
        language[this.props.currentLanguage].CheckInputCorrect,
      );
    } else {
      if (
        RemoveDot.length === 3 &&
        (InputKey === 'A' || InputKey === 'C') &&
        !onlyWord.endsWith('**')
      ) {
        if (toProcessFormula[idx].selected === false) {
          let processFormulaAmount = '';
          let getcurrentAmount = CheckOri.substring(CheckOri.indexOf('#'));
          let getEachAmount = getcurrentAmount.split('#');
          let LastInputAmount = this.state.LastInputAmount;
          let filterGetLastNum = toProcessFormula.filter(
            item => item.selected === true || '',
          );
          const ThreeDkeyInFormula = toProcessFormula.filter(
            item => item.key === 'A' || item.key === 'C',
          );
          if (filterGetLastNum.length > 0) {
            filterGetLastNum = filterGetLastNum.pop().num;
            let getLastSelectAmount = '';
            if (
              ThreeDkeyInFormula[0].selected === true &&
              ThreeDkeyInFormula[1].selected === false
            ) {
              getLastSelectAmount = getEachAmount[1] || '';
            } else if (
              ThreeDkeyInFormula[0].selected === false &&
              ThreeDkeyInFormula[1].selected === true
            ) {
              getLastSelectAmount = getEachAmount[2] || '';
            }
            if (getLastSelectAmount > 0 || getLastSelectAmount !== '') {
              LastInputAmount = '';
              for (let x = 0; x < toProcessFormula.length; x++) {
                if (x !== idx) {
                  toProcessFormula[x].selected = false;
                }
              }
            }
          }
          toProcessFormula[idx].selected = true;
          keyInFormula = toProcessFormula.filter(
            item => item.key === 'A' || item.key === 'C',
          );
          if (keyInFormula.length === 0) {
            keyInFormula = ['A', 'C'];
          }
          if (keyInFormula.length > 0) {
            if (CheckTag.length < keyInFormula.length) {
              let Check3DFormulaNum = '';
              if (keyInFormula.length === 1) {
                Check3DFormulaNum = 1;
              } else if (keyInFormula.length === 2) {
                if (
                  (keyInFormula[1].key === 'A' &&
                    CheckAC[1] === 'A' &&
                    keyInFormula[0].selected === false) ||
                  (keyInFormula[1].key === 'C' &&
                    CheckAC[1] === 'C' &&
                    keyInFormula[0].selected === false)
                ) {
                  Check3DFormulaNum = keyInFormula.length - CheckTag.length;
                } else {
                  keyInFormula = keyInFormula.filter(
                    item => item.selected === true,
                  );
                  Check3DFormulaNum = keyInFormula.length - CheckTag.length;
                }
              }
              for (let i = 0; i < Check3DFormulaNum; i++) {
                NumberTag += '#';
              }
              lineData[this.state.SelectedLine].originalWord =
                lineData[this.state.SelectedLine].originalWord + NumberTag;
            }
            processFormulaAmount = processPad2KeyAmount(
              lineData,
              this.state.SelectedLine,
              keyInFormula,
              LastInputAmount,
            );
            this.setState({LastInputAmount: LastInputAmount});
            lineData[this.state.SelectedLine].originalWord =
              processFormulaAmount;
          }
        } else {
          toProcessFormula[idx].selected = false;
          keyInFormula = toProcessFormula.filter(
            item => item.key === 'A' || item.key === 'C',
          );
          let processFormulaAmount = processPad2KeyAmount(
            lineData,
            this.state.SelectedLine,
            keyInFormula,
            '',
          );
          lineData[this.state.SelectedLine].originalWord = processFormulaAmount;
          this.setState({LastInputAmount: ''});
        }
      }
      if (
        RemoveDot.length === 4 ||
        (RemoveDot.length === 3 && onlyWord.endsWith('**'))
      ) {
        if (toProcessFormula[idx].selected === false) {
          let processFormulaAmount = '';
          let getcurrentAmount = CheckOri.substring(CheckOri.indexOf('#'));
          let getEachAmount = getcurrentAmount.split('#');
          let LastInputAmount = this.state.LastInputAmount;
          let filterGetLastNum = toProcessFormula.filter(
            item => item.selected === true || '',
          );
          if (filterGetLastNum.length > 0) {
            filterGetLastNum = filterGetLastNum.pop().num;
            let getLastSelectAmount = getEachAmount[filterGetLastNum] || '';
            if (getLastSelectAmount > 0 || getLastSelectAmount !== '') {
              LastInputAmount = '';
              for (let x = 0; x < toProcessFormula.length; x++) {
                if (x !== idx) {
                  toProcessFormula[x].selected = false;
                }
              }
            }
          }
          toProcessFormula[idx].selected = true;
          keyInFormula = toProcessFormula.filter(
            item => item.selected === true,
          );
          if (keyInFormula.length > 0) {
            if (CheckTag.length < FormulaNum) {
              FormulaNum = FormulaNum - CheckTag.length;
              for (let i = 0; i < FormulaNum; i++) {
                NumberTag += '#';
              }
              lineData[this.state.SelectedLine].originalWord =
                lineData[this.state.SelectedLine].originalWord + NumberTag;
            }
            processFormulaAmount = processPad2KeyAmount(
              lineData,
              this.state.SelectedLine,
              toProcessFormula,
              LastInputAmount,
            );
            this.setState({LastInputAmount: LastInputAmount});
            lineData[this.state.SelectedLine].originalWord =
              processFormulaAmount;
          }
        } else {
          toProcessFormula[idx].selected = false;
          let processFormulaAmount = processPad2KeyAmount(
            lineData,
            this.state.SelectedLine,
            toProcessFormula,
            '',
          );
          lineData[this.state.SelectedLine].originalWord = processFormulaAmount;
          this.setState({LastInputAmount: ''});
        }
      }
      const SelectedWord = document.getElementById('BetText')
      SelectedWord.focus()
      this.ProcessWords(lineData);
    }
    this.setState({pad2KeyFormula: toProcessFormula});
  }

  async onPressAmountRoll() {
    const lineData = this.state.lineData;
    let CheckOri = lineData[this.state.SelectedLine].originalWord;
    CheckOri = filterStartTag(CheckOri);
    if (CheckOri.split('#').length === 2) {
      let processFormulaAmount = [];
      let Formula = this.state.DisplayFormula.split(',');
      for (var i = 1; i < Formula.length; i++) {
        processFormulaAmount.push('#' + CheckOri.split('#')[1]);
      }
      lineData[this.state.SelectedLine].originalWord =
        lineData[this.state.SelectedLine].originalWord +
        processFormulaAmount.join().replace(/([,])/g, '');
      this.NextLineButtonFunction('Nextline');
      this.ProcessWords(lineData);
      const SelectedWord = document.getElementById('BetText')
      SelectedWord.focus()
    } else {
      alert(
        language[this.props.currentLanguage].CheckInputCorrect,
      );
    }
  }

  NameHandleText(value, field) {
    this.setState({ [field]: value });
  }

  async CheckUserBalance(SelectedUsername) {
    this.setState({loading: true});
    const getBalance = await getCashWalletAmt(
      SelectedUsername,
      this.props.username,
      this.props.token,
    );
    if (getBalance === 'TimeOut') {
      this.LogoutFunction();
    } else if (getBalance.CashWallet === '---') {
      this.setState({
        loading: false,
        userBalance: '---',
      });
    } else {
      this.setState({
        loading: false,
        userBalance: getBalance.CashWallet.toFixed(2),
      });
    }
  }

  multilinePasteTextHandleText(value, field) {
    this.setState({ [field]: value });
  }

  async CovertPaste() {
    const getCopyText = await navigator.clipboard.readText();
    const processWord = processEditPasteText(
      this.state.pfPosition,
      this.state.DisplayPlatform,
      getCopyText.split('\n'),
      this.state.JingNan,
    );
    this.setState({multilinePasteText: processWord.join().replace(/,/g, '\n')});
  }

  async onPressPasteForConvert() {
    const getfilterArray = processCopyText(
      this.state.multilinePasteText.split('\n'),
    );
    this.pasteFunction(getfilterArray);
    this.setState({editPasteModalVisible: false});
  }
 
  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img className= "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <div className='Bbody' style={{overflow: 'hidden'}}>
          {/* <div class="BT-container">  */}
          <div className='box'>
            <div className='BT-HEAD'> 
              <div className='PageHeaderMainContainer' >
                <div className='PageHeaderLeftContainer'>
                  <div className='PageHeaderLeftBackButton'
                    onClick={() => this.props.history.goBack()}>
                    {language[this.props.currentLanguage].Back}
                  </div>
                </div>
                <div className='PageHeaderMiddleContainer'>
                  {language[this.props.currentLanguage].Ticket}
                </div>
                <div className='PageHeaderRightContainer' style={{flexDirection: 'row'}}>
                  <button className='mainRightFunctionButton' style={{marginRight: 10}} onClick={() =>this.Str8PasteFuntion()}>
                    <img className="mainRightIcon" src={pasteIcon} alt="pasteIcon"/>
                    {/* {language[this.props.currentLanguage].More} */}
                  </button>
                  <button className='mainRightFunctionButton' onClick={() =>this.setFunctionModalVisible(!this.state.FunctionModalVisible)}>
                    <img className="mainRightIcon" src={moreIcon} alt="moreIcon"/>
                  </button>
                </div>
              </div>
              <div className='PageUserSelectnSendContainer'>
                <button className='btIDButton'
                  onClick={() => this.setUserModalVisible(true)}>
                  {this.state.Username}
                  <div style={{ marginLeft: 'auto', marginRight: '5px'}}>▼</div>
                </button>
                <button className='btSendButton'
                  onClick={() => this.SendButtonFunction()}>
                  {language[this.props.currentLanguage].Send}
                </button>
              </div>
              <div className="NameContainer">
                <input
                  className="NameInputTextBox"
                  type="text"
                  maxLength={20}
                  value={this.state.nameInput}
                  onChange={(e) => this.NameHandleText(e.target.value, 'nameInput')}
                  ref={(input) => { this.nameInput = input; }}
                  placeholder={language[this.props.currentLanguage].Remark}
                  onFocus={() => this.setKeyboardVisible(true)}
                  onBlur={() => this.setKeyboardVisible(!this.state.keyboardVisible)}
                />
                <div className='BalanceText'>$ {this.state.userBalance}</div>
              </div>
            </div>
            <div className="BT-body">
              {this.state.lineData.map((item, idx) =>{
                return (
                  <button className={[this.state.SelectedLine === idx ? 'BettingMultilineInputContainer' : 'BettingMultilineInputSelectedContainer']} 
                    key={idx}
                    style={{wordBreak: 'break-all' }}
                    onClick={() => this.SelectLineFunction({Line: idx})}
                    id={this.state.SelectedLine === idx ? `BetText` : ``}
                    autoFocus={this.state.SelectedLine === idx ? true : false}>
                    {/* {item.Mode !== '' && item.Mode !== 'IB' && (
                      <div className='SpecialWordButtonText'>
                  Special: {item.Mode}
                      </div>
                    )} */}
                    {item.originalWord !== '' && (
                      <div className='WordButtonText'>{item.translatedWord}</div>
                    )}
                    {item.originalWord === '' && (
                      <div className='WordButtonText' style={{opacity: 0}}>-</div>
                    )}  
                  </button>
                )
              })}
            </div>
            <div className="FormulaContainer" >
              <div className='FormulaAmountText' style={{wordBreak: 'break-all' }}> ${this.state.OverallAmount.toFixed(2)}</div>
              <div className='FormulaDisplayText' style={{wordBreak: 'break-all' }}>{this.state.DisplayFormula}</div>
              <div className='FormulaAmountText' style={{wordBreak: 'break-all' }}>Line{this.state.SelectedLine}/{this.state.WordLine}</div>
            </div>
            {/*First Keyboard*/ }
            { (this.state.KeyboardType === 'A' && this.state.keyboardVisible === true) && 
            <div className='PageKeyboardContainer'>
              <div className="PressAbleContainer">
                <button className="firstRowButton" style={{ width: '20%'}} onClick={() => this.PreviosLineButtonFunction()}>▲</button>
                <button className="firstRowButton" style={{ width: '21%'}} onClick={() => this.ReverseNumFunction()}>{language[this.props.currentLanguage].Reverse}</button>
                <button className="firstRowButton"style={{ width: '16%'}} onClick={() => this.onPressNumberRange()}> {' '}
            －{' '}</button>
                <button className="firstRowButton"style={{ width: '23%'}} onClick={() => this.onPressBackspace(0)}><img className = "BackSpaceIcon" src={BackSpaceIcon} alt="BigCo Inc. logo"/></button>
                <button className="firstRowButton"style={{ width: '20%'}} onClick={() => this.NextLineButtonFunction('Nextline')}>▼</button>
              </div>
              <div className="NumberContainer">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, idx) => {
                  return (
                    <div className='NumPadButton' key={idx}>
                      <button  className='NumPadIconLabel' onClick={() => this.PressNumberFunction(item)}>
                        {item}
                      </button>
                    </div>
                  )
                })}
              </div>
              <div className="PressAbleContainer">
                <button className="firstRowButton" onClick={() => this.onPressDot()}>*</button>
                <button className="firstRowButton" onClick={() => this.onPressHit()}>  {' '}
                  {language[this.props.currentLanguage].box}{' '}
                </button>
                <button className="firstRowButton" onClick={() => this.PressNumberFunction(0)}>0</button>
                <button className="firstRowButton"
                  onClick={() => this.onPressIBOX()}>
                  {language[this.props.currentLanguage].IB}</button>
                <button className="firstRowButton" style={{wordBreak: 'break-all' }} onClick={() => this.onPresstag()}>#</button>
              </div>
            </div>
            }
            {/*second Keyboard / Third Keyboard*/ }
            { (this.state.KeyboardType === 'B' && this.state.keyboardVisible === true) && 
            <div className='PageKeyboardContainer'>
              <div className="PressAbleContainer">
                {this.state.pad2KeyFormula.map((item, idx) => {
                  return (
                    <button 
                      className={[item.selected === false
                        ?  'firstRowButton' : 'Pad2SelectedFormulaButton']} 
                      onClick={() => this.Pad2PressFormula(idx, item.key)}
                      key={idx}>
                      {item.key}
                    </button>
                  )
                })}
              </div>
              <div className='SecondNumpadMiddleContainer'>
                <div className="SecondNumpadContainer">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, idx) => {
                    return (
                      <div className='NumPadButton' key={idx}>
                        <button  className='NumPadIconLabel' onClick={() => this.PressNumberFunction(item)}>
                          {item}
                        </button>
                      </div>
                    )
                  })}
                </div>
                <div className="SecondNumpadContainer2">
                  <button className='SecondNumPadIconLabel' onClick={() => this.setModalVisible(true)}>{'D'}</button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.onPressBackspace(0)}><img className = "BackSpaceIcon" src={BackSpaceIcon} alt="BigCo Inc. logo"/></button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.onPressAmountRoll()}><img className = "BackSpaceIcon" src={spaceBarIcon} alt="BigCo Inc. logo"/></button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.ClearRow()}><img className = "BackSpaceIcon" src={deleteIcon} alt="BigCo Inc. logo"/></button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.NextLineButtonFunction('Nextline')}>▼</button>
                  <button className='SecondNumPadIconLabel' onClick={() =>  this.setKeyboardVisible(!this.state.keyboardVisible)}>⌨</button>
                </div>
              </div>
              <div className="PressAbleContainer">
                <button className="firstRowButton" onClick={() => this.onPressDot()}>*</button>
                <button className="firstRowButton" onClick={() => this.PressNumberFunction(0)}>0</button>
                <button className="firstRowButton" style={{wordBreak: 'break-all' }} onClick={() => this.onPresstag()}>#</button>
                <button className="firstRowButton" onClick={() =>  this.onPressNumberRange()}>－</button>
                <button className="firstRowButton" style={{ color: 'red'}}
                  onClick={() => this.SendButtonFunction()}>
                  {language[this.props.currentLanguage].Buy}
                </button>
              </div>
            </div>
            }
            { (this.state.KeyboardType === 'C' && this.state.keyboardVisible === true) && 
            <div className='PageKeyboardContainer'>
              <div className="PressAbleContainer">
                <button className='firstRowButton'
                  onClick={() => this.onPressHit()}>
                  {' '}
                  {language[this.props.currentLanguage].box}{' '}
                </button>
                <button className='firstRowButton'
                  onClick={() => this.onPressIBOX()}>
                  {' '}{language[this.props.currentLanguage].IB}{' '}
                </button>
                <button className='firstRowButton'
                  onClick={() => this.ReverseNumFunction()}>
                  {language[this.props.currentLanguage].Reverse}
                </button>
                <button className='firstRowButton'
                  onClick={() => this.onPressNumberRange()}>
                  {' '}
                      －{' '}
                </button>
                <button className='firstRowButton'
                  onClick={() => this.PreviosLineButtonFunction()}>
                 ▲
                </button>
              </div>
              <div className='SecondNumpadMiddleContainer'>
                <div className="SecondNumpadContainer">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, idx) => {
                    return (
                      <div className='NumPadButton' key={idx}>
                        <button  className='NumPadIconLabel' onClick={() => this.PressNumberFunction(item)}>
                          {item}
                        </button>
                      </div>
                    )
                  })}
                </div>
                <div className="SecondNumpadContainer2">
                  <button className='SecondNumPadIconLabel' onClick={() => this.setModalVisible(true)}>{'D'}</button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.onPressBackspace(0)}><img className = "BackSpaceIcon" src={BackSpaceIcon} alt="BigCo Inc. logo"/></button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.onPressAmountRoll()}><img className = "BackSpaceIcon" src={spaceBarIcon} alt="BigCo Inc. logo"/></button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.ClearRow()}><img className = "BackSpaceIcon" src={deleteIcon} alt="BigCo Inc. logo"/></button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.NextLineButtonFunction('Nextline')}>▼</button>
                  <button className='SecondNumPadIconLabel' onClick={() =>  this.setKeyboardVisible(!this.state.keyboardVisible)}>⌨</button>
                </div>
              </div>
              <div className="PressAbleContainer">
                <button className="firstRowButton" onClick={() => this.onPressDot()}>*</button>
                <button className="firstRowButton" onClick={() => this.PressNumberFunction(0)}>0</button>
                <button className="firstRowButton" style={{wordBreak: 'break-all' }} onClick={() => this.onPresstag()}>#</button>
                <button className="firstRowButton" onClick={() =>  this.SelectedFunction(language[this.props.currentLanguage].AddNewLine)}>+</button>
                <button className="firstRowButton" style={{ color: 'red'}}
                  onClick={() => this.SendButtonFunction()}>
                  {language[this.props.currentLanguage].Buy}
                </button>
              </div>
            </div>
            }
            { (this.state.KeyboardType === 'D' && this.state.keyboardVisible === true) && 
            <div className='PageKeyboardContainer'>
              <div className="PressAbleContainer">
                {this.state.pad2KeyFormula.map((item, idx) => {
                  return (
                    <button 
                      className={[item.selected === false
                        ?  'firstRowButton' : 'Pad2SelectedFormulaButton']} 
                      onClick={() => this.Pad2PressFormula(idx, item.key)}
                      key={idx}>
                      {item.key}
                    </button>
                  )
                })}
              </div>
              <div className='SecondNumpadMiddleContainer'>
                <div className="SecondNumpadContainer">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, idx) => {
                    return (
                      <div className='NumPadButton' key={idx}>
                        <button  className='NumPadIconLabel' onClick={() => this.PressNumberFunction(item)}>
                          {item}
                        </button>
                      </div>
                    )
                  })}
                </div>
                <div className="SecondNumpadContainer2">
                  <button className='SecondNumPadIconLabel' onClick={() => this.onPressHit()}>{'Box'}</button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.onPressBackspace(0)}><img className = "BackSpaceIcon" src={BackSpaceIcon} alt="BigCo Inc. logo"/></button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.onPressIBOX()}>{'iBox'}</button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.ClearRow()}><img className = "BackSpaceIcon" src={deleteIcon} alt="BigCo Inc. logo"/></button>
                  <button className='SecondNumPadIconLabel' onClick={() => this.NextLineButtonFunction('Nextline')}>▼</button>
                  <button className='SecondNumPadIconLabel' onClick={() =>  this.setKeyboardVisible(!this.state.keyboardVisible)}>⌨</button>
                </div>
              </div>
              <div className="PressAbleContainer">
                <button className="firstRowButton" onClick={() => this.onPressDot()}>*</button>
                <button className="firstRowButton" onClick={() => this.PressNumberFunction(0)}>0</button>
                <button className="firstRowButton" style={{wordBreak: 'break-all' }} onClick={() => this.onPresstag()}>#</button>
                <button className="firstRowButton" onClick={() =>  this.SelectedFunction(language[this.props.currentLanguage].AddNewLine)}>+</button>
                <button className="firstRowButton" style={{ color: 'red'}}
                  onClick={() => this.SendButtonFunction()}>
                  {language[this.props.currentLanguage].Buy}
                </button>
              </div>
            </div>
            }
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.modalVisible}
              contentClassName="br23">
              <Modal.Body className='DateModalBody' style={{ padding: 10 }}>
                <div className='DateModalHeader'>
                  <div className='DateModalHeaderText'>D#</div>
                  <div className='DateModalHeaderText'>
                    <button className='DateModalCloseButton' onClick={() =>  this.setModalVisible(!this.state.modalVisible)}> 
                      {language[this.props.currentLanguage].Close}
                    </button>
                  </div>
                </div>
                {this.state.ModalDate.DateLoop.map((item, idx) => {
                  return (
                    <button className='dateListButton' 
                      key={idx}
                      onClick={() => this.SelectDateFunction(idx)}>
                      {item.Date} {item.CheckMark && (
                        <img className="CheckmarkIcon" src={CheckmarkIcon} alt="CheckmarkIcon"/>
                      )}
                    </button>
                  )
                })}
                <div className='dateListFooter'>
                  <button className='dateFooterButton'
                    onClick={() => this.onpressPeriod(2)}>
                    {language[this.props.currentLanguage].TwoPeriod}
                  </button>
                  <button className='dateFooterButton'
                    onClick={() => this.onpressPeriod(3)}>
                    {language[this.props.currentLanguage].ThreePeriod}
                  </button>
                  <button className='dateFooterButton'
                    onClick={() => this.onpressLongTerm()}>
                    {language[this.props.currentLanguage].Longterm}
                  </button>
                  <button className='dateFooterButton'
                    onClick={() => this.onPressConfirmSelectDate()}>
                    {language[this.props.currentLanguage].Confirm}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.UserModalVisible}
              contentClassName="br23">
              <Modal.Body className='DateModalBody' style={{ padding: 10}}>
                <div className='DateModalHeader'>
                  <div className='DateModalHeaderText'>
                    {language[this.props.currentLanguage].User}
                  </div>
                  <div className='DateModalHeaderText'>
                    <button className='DateModalCloseButton' onClick={() =>  this.setUserModalVisible(!this.state.UserModalVisible)}> 
                      {language[this.props.currentLanguage].Close}
                    </button>
                  </div>
                </div>
                {this.props.UserAndDownlineData.map((item, idx) => {
                  return (
                    <button className='dateListButton' 
                      key={idx}
                      onClick={() => this.SelectUserModal(item.ID, item.Username, idx)}>
                      {item.Username}
                    </button>
                  )
                })}
              </Modal.Body>
            </Modal>
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.FunctionModalVisible}
              contentClassName="br23">
              <Modal.Body>
                <div className='DateModalHeader'>
                  <div className='DateModalHeaderText'>
                    {language[this.props.currentLanguage].FunctionLanguage}
                  </div>
                  <div className='DateModalHeaderText'>
                    <button className='DateModalCloseButton' onClick={() => this.setFunctionModalVisible(
                      !this.state.FunctionModalVisible,
                    )}> 
                      {language[this.props.currentLanguage].Close}
                    </button>
                  </div>
                </div>
                {[
                  language[this.props.currentLanguage].ClearAllAlertTitle,
                  language[this.props.currentLanguage].Paste,
                  language[this.props.currentLanguage].editPaste,
                  language[this.props.currentLanguage].AddNewLine,
                ].map((item, idx) => {
                  return (
                    <button className='dateListButton' 
                      key={idx}
                      onClick={() =>this.SelectedFunction(item)}>
                      {item}
                    </button>
                  )
                })}
                {/* {this.state.KeyboardSelect.map((item, idx) => {
                  return (
                    <button className='dateListButton' 
                      key={idx}
                      onClick={() =>this.SelectedKeyboard(item.Keyboard)}>
                      {item.Keyboard} {item.CheckMark === true && (
                        <img className="CheckmarkIcon" src={CheckmarkIcon} alt="CheckmarkIcon"/>
                      )}
                    </button>
                  )
                })} */}
              </Modal.Body>
            </Modal>
            <Modal transparent={true}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.editPasteModalVisible}
              contentClassName="br23">
              <Modal.Body>
                <div className='DateModalHeader'>
                  <div className='DateModalHeaderText'>
                    {language[this.props.currentLanguage].editPaste}
                  </div>
                  <div className='DateModalHeaderText'>
                    <button className='DateModalCloseButton' onClick={() => this.setPasteEditModalVisible(
                      !this.state.editPasteModalVisible,
                    )}> 
                      {language[this.props.currentLanguage].Close}
                    </button>
                  </div>
                </div>
                <div className='ModalPlatformTextContainer'>
                  {this.state.PlatFormList.map((item, idx) => {
                    if (item.PlatForm !== '-') {
                      return (
                        <div className='ModalPlatformText'
                          key={idx}
                          value={item.PlatForm}>
                          {idx + 1}-{item.PlatForm}
                        </div>
                      );
                    }
                  })}
                </div>
                <textarea className='pasteEditModalFooterInput' 
                  value={this.state.multilinePasteText}
                  onChange={(e) => this.multilinePasteTextHandleText(e.target.value, 'multilinePasteText')}
                  rows={13}
                  cols={13}
                />
                <div className='pasteEditModalFooter'>
                  <button className='pasteEditModalFooterButton'
                    onClick={() => this.CovertPaste()}>
                    {language[this.props.currentLanguage].ConvertPaste}
                  </button>
                  <button className='pasteEditModalFooterButton'
                    onClick={() => this.onPressPasteForConvert()}>
                    {language[this.props.currentLanguage].Add}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
            {/* </div>  */}
          </div>
        </div> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
    ReBuyData: state.ReBuyData,
    ReBuyUserID: state.ReBuyUserID,
    ReBuyUser: state.ReBuyUser,
    UserAndDownlineData: state.UserAndDownlineData,
    SelectedUserKeyFormula: state.SelectedUserKeyFormula,
    UserJingNan: state.UserJingNan,
    OneStarBao: state.OneStarBao,
    currentKeyboard: state.currentKeyboard,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setReceiptData: (ReceiptData, BuyMode) => {
      dispatch({
        type: 'RECEIPTDATA_FOR_PASS',
        payload: {
          ReceiptData: ReceiptData,
          BuyMode: BuyMode,
        },
      });
    },
    SetRebuyData: (SelectedData, SelectedUserID, SelectedUser) => {
      dispatch({
        type: 'REBUYDATA_FOR_PASS',
        payload: {
          ReBuyData: SelectedData,
          ReBuyUserID: SelectedUserID,
          ReBuyUser: SelectedUser,
        },
      });
    },
    setLoginStatus: (loginStatus, ID, Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          login: loginStatus,
          id: ID,
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(betting);
  