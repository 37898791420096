/* eslint-disable no-mixed-operators */
import '../App.css';
// import JSEncrypt from 'jsencrypt';
import React from 'react';
import { connect } from 'react-redux';
import {language} from '../language';
import LoadingLogo from '../img/loading.gif';
import Modal from 'react-bootstrap/Modal';

class receiptManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      UserModalVisible: false,
      UserID: '',
      Username: '',
    };
    this.SelectUserModal = this.SelectUserModal.bind(this);
    this.ReceiptCheck = this.ReceiptCheck.bind(this);
  }

  async setUserModalVisible() {
    const CheckVisible = this.state.UserModalVisible;
    if (CheckVisible === true) {
      this.setState({UserModalVisible: false});
    } else {
      this.setState({UserModalVisible: true});
    }
  }
  async SelectUserModal(SelectedID, SelectedUsername, idx) {
    let getUserKeyInResult = [];
    getUserKeyInResult.push({
      KeyIn1: this.props.UserAndDownlineData[idx].KeyIn1,
      KeyIn2: this.props.UserAndDownlineData[idx].KeyIn2,
    });
    this.setState({
      UserID: SelectedID,
      Username: SelectedUsername,
      UserModalVisible: false,
    });
    sessionStorage.setItem('UserIDForSearch', SelectedID);
    sessionStorage.setItem('UserForSearch', SelectedUsername);
    this.props.setCheckResultInfo(
      SelectedID,
      SelectedUsername,
      '',
      '',
      '',
    );
  }

  async componentDidMount() { 
    if (this.props.UserAndDownlineData === '') {
      this.props.history.push('/')
    }
    let getUserKeyInResult = [];
    await getUserKeyInResult.push({
      KeyIn1: this.props.UserAndDownlineData[0].KeyIn1,
      KeyIn2: this.props.UserAndDownlineData[0].KeyIn2,
    });
    this.setState({
      UserID: this.props.id,
      Username: this.props.username,
    });
    sessionStorage.setItem('UserIDForSearch', this.props.id);
    sessionStorage.setItem('UserForSearch',  this.props.username);
    this.props.setCheckResultInfo(
      this.props.id,
      this.props.username,
      '',
      '',
      '',
    );
    sessionStorage.setItem('oldTicketDate', '');
    sessionStorage.setItem('oldTicketDateBy',  '');
    sessionStorage.setItem('oldTicketNumber',  '');
    sessionStorage.setItem('oldTicketIDType',  '');
    sessionStorage.setItem('oldTicketIndex',  '');
    sessionStorage.setItem('oldTicketTotal',  '');
    this.props.setOldTicketInfo('', '', '', '', '', '');
    this.setState({loading: false});
  }

  async ReceiptCheck(Hint) {
    if (Hint === 'SearchReceipt') {
      this.props.history.push('/displayReceipt');
    } else if (Hint === 'LongTerm') {
      this.props.history.push('/displayLongtermReceipt');
    } else if (Hint === 'SearchByNumber') {
      this.props.history.push('/displayNumberReceipt');
    }
  }
 

  render() {
    if (this.state.loading) {
      return (
        <div className="LoginPageContainer" style={{ justifyContent: 'center'}}>
          <img className= "loadingSize" src={LoadingLogo} alt="BigCo Inc. logo"/>
        </div>
      );
    } else {
      return (
        <body>
          {/* <div class="BT-container">  */}
          <div className='PageHeaderMainContainer'>
            <div className='PageHeaderLeftContainer'>
              <div className='PageHeaderLeftBackButton'
                onClick={() => this.props.history.goBack()}>
                {language[this.props.currentLanguage].Back}
              </div>
            </div>
            <div className='PageHeaderMiddleContainer'>
              {language[this.props.currentLanguage].TicketManagement}
            </div>
            <div className='PageHeaderRightContainer'>
            </div>
          </div>
          <div className='PageUserSelectnSendContainer'>
            <button className='btIDButton'
              style={{ width: '100%'}}
              onClick={() => this.setUserModalVisible(true)}>
              {this.state.Username}
              <div className='ReceiptMangeUserText'
                style={{
                  marginRight: 10,
                }}>
                {language[this.props.currentLanguage].Change}
              </div>
            </button>
          </div>
          <div class="BT-body" style={{ height: 'auto', width: '98%', marginLeft: 'auto', marginRight: 'auto'}}>
            <button className='ReceiptManageButton'
              onClick={() => this.ReceiptCheck('SearchReceipt')}> 
              {language[this.props.currentLanguage].BrowseTicket}
            </button>
            <button className='ReceiptManageButton'
              onClick={() => this.ReceiptCheck('LongTerm')}>  
              {language[this.props.currentLanguage].ShowAutoTicket}
            </button>
            <button className='ReceiptManageButton'
              onClick={() => this.ReceiptCheck('SearchByNumber')}>  
              {language[this.props.currentLanguage].SearchByNumber}
            </button>
          </div> 
          <Modal transparent={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.UserModalVisible}
            contentClassName="br23">
            <Modal.Body className='DateModalBody' style={{ padding: 10}}>
              <div className='DateModalHeader'>
                <div className='DateModalHeaderText'>
                  {language[this.props.currentLanguage].User}
                </div>
                <div className='DateModalHeaderText'>
                  <button className='DateModalCloseButton' onClick={() =>  this.setUserModalVisible(!this.state.UserModalVisible)}> 
                    {language[this.props.currentLanguage].Close}
                  </button>
                </div>
              </div>
              {this.props.UserAndDownlineData.map((item, idx) => {
                return (
                  <button className='dateListButton' 
                    key={idx}
                    onClick={() => this.SelectUserModal(item.ID, item.Username, idx)}>
                    {item.Username}
                  </button>
                )
              })}
            </Modal.Body>
          </Modal>
          {/* </div>  */}
        </body> 
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id,
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    checkResultdate: state.checkResultdate,
    checkResultPlatForm: state.checkResultPlatForm,
    UserForSearch: state.UserForSearch,
    currentLanguage: state.currentLanguage,
    UserAndDownlineData: state.UserAndDownlineData,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setCheckResultInfo: (
      SelectedUserID,
      SelectedUser,
      SelectedDateFrom,
      SelectedDateTo,
      UserKeyFormula,
    ) => {
      dispatch({
        type: 'INFO_FOR_SEARCH',
        payload: {
          UserIDForSearch: SelectedUserID,
          UserForSearch: SelectedUser,
          SearchDateFrom: SelectedDateFrom,
          SearchDateTo: SelectedDateTo,
          SelectedUserKeyFormula: UserKeyFormula,
        },
      });
    },
    setOldTicketInfo: (TicketDate, TicketDateBy, TicketNum, IDType, index, oldTotal) => {
      dispatch({
        type: 'OldTicketInfo',
        payload: {
          oldTicketDate: TicketDate,
          oldTicketDateBy: TicketDateBy,
          oldTicketNumber: TicketNum,
          oldTicketIDType: IDType,
          oldTicketIndex: index,
          oldTicketTotal: oldTotal,
        },
      });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(receiptManage);
  