import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Menu from './Page/Menu';
import betting from './Page/betting';
import bettingRedbill from './Page/bettingRedbill';
import bettingSGD from './Page/bettingSGD';
import bettingTHB from './Page/bettingTHB';
import SimpleBT from './Page/SimpleBT';
import receiptAfterSubmit from './Page/receiptAfterSubmit';
import information from './Page/information';
import reportSearch from './Page/reportSearch';
import displayReportDetails from './Page/displayReportDetails';
import displayReport from './Page/displayReport';
import Settings from './Page/Settings';
import ChangePassword from './Page/ChangePassword';
import Result from './Page/Result';
import receiptManage from './Page/receiptManage';
import displayReceipt from './Page/displayReceipt';
import displayLongtermReceipt from './Page/displayLongtermReceipt';
import reportBetChecking from './Page/reportBetChecking';
import displayNumberReceipt from './Page/displayNumberReceipt';
import dailySalesReport from './Page/dailySalesReport';
import displayDailySales from './Page/displayDailySales';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';

class MainComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return(
      <Router style={{ position: 'relative' }}>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Menu} />
            <Route exact path="/v2" component={SimpleBT} />
            <Route exact path="/betting" component={betting} />
            <Route exact path="/bettingSGD" component={bettingSGD} />
            <Route exact path="/bettingTHB" component={bettingTHB} />
            <Route exact path="/information" component={information} />
            <Route exact path="/Settings" component={Settings} options={{unmountOnBlur: true}} />
            <Route exact path="/reportSearch" component={reportSearch} />
            <Route exact path="/displayReport" component={displayReport} />
            <Route exact path="/displayReportDetails" component={displayReportDetails} />
            <Route exact path="/ChangePassword" component={ChangePassword} />
            <Route exact path="/Result" component={Result} />
            <Route exact path="/bettingRedbill" component={bettingRedbill} />
            <Route exact path="/SimpleBT" component={SimpleBT} />
            <Route exact path="/receiptAfterSubmit" component={receiptAfterSubmit} options={{unmountOnBlur: true}} />
            <Route exact path="/receiptManage" component={receiptManage} options={{unmountOnBlur: true}} />
            <Route exact path="/displayReceipt" component={displayReceipt} options={{unmountOnBlur: true}} />
            <Route exact path="/displayLongtermReceipt" component={displayLongtermReceipt} options={{unmountOnBlur: true}} />
            <Route exact path="/reportBetChecking" component={reportBetChecking} options={{unmountOnBlur: true}} />
            <Route exact path="/displayNumberReceipt" component={displayNumberReceipt} options={{unmountOnBlur: true}} />
            <Route exact path="/dailySalesReport" component={dailySalesReport} options={{unmountOnBlur: true}} />
            <Route exact path="/displayDailySales" component={displayDailySales} options={{unmountOnBlur: true}} />
          </Switch>
        </div>
      </Router>
    )
  };
}

function mapStateToProps(state, ownProps) {
  return {
    username: state.username,
    token: state.token,
    userID: state.userID,
    Role: state.Role,
    currentLanguage: state.currentLanguage,
    loginv2: state.loginv2,
  };
}

export default connect(mapStateToProps, null)(MainComponent);
